<template>
  <div class="app-container">
    <div class="overlay"></div>
    <router-view/>
  </div>
</template>

<style>
.clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.parent::-webkit-scrollbar {
    width: 0%;
    height: 0%;
}
.degrade1 {
    background: linear-gradient(to top left, #f3f4f6, #dbeafe, #bfdbfe, rgb(191 219 254 / 0.5));
}
.degrade3 {
    background: linear-gradient(to right, rgb(54, 54, 152), rgb(157, 21, 157), rgba(219, 60, 219, 0.799), rgba(196, 65, 243, 0.888));
}
.hoverr:hover {
    background: linear-gradient(to right, rgb(54, 54, 152), rgb(157, 21, 157), rgba(219, 60, 219, 0.799), rgba(196, 65, 243, 0.888));
}
.degrade2::before {
    content: '';
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #93c5fd;
    position: absolute;
    bottom: -100px;
    left: -100px;
    z-index: -10;
}
.label {
    transition: 0.2s;
}
.input-text:focus ~ .label {
    position: absolute;
    top: -10px;
    background: #f3f4f6;
    padding: 0px 5px;
    font-size: 12px;
}
.not-empty ~ .label {
    position: absolute;
    top: -10px;
    background: #f3f4f6;
    padding: 0px 5px;
    font-size: 12px;
}
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.spinner {
   width: 28px;
   height: 28px;
   border-radius: 50%;
   padding: 1.1px;
   background: conic-gradient(#ffffff00 10%,#ffffff) content-box;
   -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
        radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 9px));
   -webkit-mask-composite: destination-in;
   mask-composite: intersect;
   animation: spinner-d55elj 1s infinite steps(10);
}

@keyframes spinner-d55elj {
   to {
      transform: rotate(1turn);
   }
}

/* Empêcher la sélection de texte */
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, actuellement supportée par Chrome, Opera, and Edge */
}

/* Empêcher le clic droit */
body {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
  /* Empêcher le clic droit */
  context-menu: none;
}

.app-container {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.1); /* Couleur de l'overlay semi-transparente */
  pointer-events: none; /* Permet aux clics de passer à travers l'overlay */
  z-index: 1000; /* Assure que l'overlay est au-dessus du contenu */
}
</style>
