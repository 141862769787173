<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section v-if="user" class="bg-blue-50 w-full h-screen md:rounded-l-[25px] overflow-y-auto parent md:p-[50px] p-[20px] mt-[120px] md:mt-0">
            <div class="w-full relative flex justify-between items-center">
                <div class="flex flex-col">
                    <h1 class="text-blue-950 md:text-3xl text-lg">Bienvenue {{ nom }} !</h1>
                    <p class="text-sm text-gray-500 ">Ici vous trouverez un récapitulatif général de vos activités</p>
                </div>
            </div>
            <div class="w-full relative flex justify-center items-center p-[20px] min-h-[200px] rounded-[20px] bg-white shadow-4xl my-[20px] flex-col">
                <div class="w-full my-[10px] p-[10px] text-xl font-bold text-gray-600">
                    <div class="w-full flex justify-between items-center">
                        <h1>
                            Il vous reste ...
                        </h1>
                        <button @click="goToSettingsPage()" class="text-blue-950 p-[10px] rounded-[5px] border border-blue-950 text-sm font-bold">Renouveler</button>
                    </div>
                </div>
                <div class="w-full my-[10px] p-[10px]">
                    <div class="flex justify-center items-cente gap-4">
                        <div class="time-box w-[70px] max-w-[calc(25%-5px)] h-[70px] md:w-[100px] md:h-[120px] flex flex-col justify-center items-center bg-gray-100 p-2 rounded-lg shadow-lg">
                          <h2 class="text-xl md:text-4xl font-bold" :class="{'text-orange-600': isPremium}">{{ timeRemaining.days }}</h2>
                          <p class="text-xs md:text-base">Jours</p>
                        </div>
                        <div class="time-box w-[70px] max-w-[calc(25%-5px)] h-[70px] md:w-[100px] md:h-[120px] flex flex-col justify-center items-center bg-gray-100 p-2 rounded-lg shadow-lg">
                          <h2 class="text-xl md:text-4xl font-bold" :class="{'text-orange-600': isPremium}">{{ timeRemaining.hours }}</h2>
                          <p class="text-xs md:text-base">Heures</p>
                        </div>
                        <div class="time-box w-[70px] max-w-[calc(25%-5px)] h-[70px] md:w-[100px] md:h-[120px] flex flex-col justify-center items-center bg-gray-100 p-2 rounded-lg shadow-lg">
                          <h2 class="text-xl md:text-4xl font-bold" :class="{'text-orange-600': isPremium}">{{ timeRemaining.minutes }}</h2>
                          <p class="text-xs md:text-base">Minutes</p>
                        </div>
                        <div class="time-box w-[70px] max-w-[calc(25%-5px)] h-[70px] md:w-[100px] md:h-[120px] flex flex-col justify-center items-center bg-gray-100 p-2 rounded-lg shadow-lg">
                          <h2 class="text-xl md:text-4xl font-bold" :class="{'text-orange-600': isPremium}">{{ timeRemaining.seconds }}</h2>
                          <p class="text-xs md:text-base">Secondes</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full relative flex justify-between items-center flex-wrap mt-[25px]">
                <div class="w-[calc(50%-10px)] h-[130px] relative flex flex-col justify-between shadow-7xl bg-white p-[20px] rounded-[10px] my-[10px]">
                    <div>
                        <i class="fa-solid fa-clock-rotate-left text-2xl text-purple-800"></i>
                    </div>
                    <div class="flex flex-col">
                        <h1 class="text-2xl font-bold text-purple-900/80">+50</h1>
                        <p class="text-sm font-semibold text-purple-900/60">Recommandations</p>
                    </div>
                </div>
                <div class="w-[calc(50%-10px)] h-[130px] relative flex flex-col justify-between overflow-hidden shadow-7xl bg-white p-[20px] rounded-[10px] my-[10px]">
                    <div>
                        <i class="fa-solid fa-book text-2xl text-orange-600"></i>
                    </div>
                    <div class="flex flex-col">
                        <h1 class="text-2xl font-bold text-purple-900/80">{{ corriges.length }}</h1>
                        <p class="text-sm font-semibold text-purple-900/60">Exercices</p>
                    </div>
                    <div v-if="isStandar" class="absolute p-[20px] bg-gray-800/90 top-0 left-0 w-full h-full hidden flex-col text-white justify-center items-center">
                        <i class="fa-solid fa-eye-slash"></i>
                        <h1 class="text-xs text-center text-white font-semibold mt-[10px]">
                            Contenu non disponible pour votre forfait
                        </h1>
                    </div>
                </div>
                <div class="w-[calc(50%-10px)] h-[130px] relative overflow-hidden flex flex-col justify-between shadow-7xl bg-white p-[20px] rounded-[10px] my-[10px]">
                    <div>
                        <i class="fa-solid fa-file-archive text-2xl md:text-purple-800 text-orange-600"></i>
                    </div>
                    <div class="flex flex-col">
                        <h1 class="text-2xl font-bold text-purple-900/80">{{ documents.length }}</h1>
                        <p class="text-sm font-semibold text-purple-900/60">Documents</p>
                    </div>
                    <div v-if="isStandar" class="absolute p-[20px] bg-gray-800/90 top-0 left-0 w-full h-full hidden flex-col text-white justify-center items-center">
                        <i class="fa-solid fa-eye-slash"></i>
                        <h1 class="text-xs text-center text-white font-semibold mt-[10px]">
                            Contenu non disponible pour votre forfait
                        </h1>
                    </div>
                </div>
                <div class="w-[calc(50%-10px)] h-[130px] relative flex flex-col justify-between shadow-7xl bg-white p-[20px] rounded-[10px] my-[10px]">
                    <div>
                        <i class="fa-solid fa-list-check text-2xl text-purple-800 md:text-orange-600 "></i>
                    </div>
                    <div class="flex flex-col">
                        <h1 class="text-2xl font-bold text-purple-900/80">{{ epreuves.length }}</h1>
                        <p class="text-sm font-semibold text-purple-900/60">Epr & Correction</p>
                    </div>
                </div>
            </div>
            <div class="w-full relative flex justify-center items-center my-[20px]">
                <ul class="w-full flex flex-col justify-center items-center">
                    <li @click="gotoRecommandation()" class="flex items-center w-full relative cursor-pointer my-[10px] p-[10px] rounded-[5px] bg-white shadow-7xl">
                        <div class="w-[30px] h-[30px] rounded-[5px] bg-gray-800 flex justify-center items-center">
                            <i class="fa-solid fa-star text-orange-400 text-xl"></i>
                        </div>
                        <div class="ml-[10px]">
                            <h1 class="text-xs font-semibold text-gray-800">{{ user.filiere }} - {{ user.year }}</h1>
                            <p class="text-[10px] font-bold text-gray-500">Document</p>
                        </div>
                    </li>
                    <li v-if="!isPremium" @click="gotoRecommandationErreur()" class="hidden items-center w-full relative cursor-pointer my-[10px] p-[10px] rounded-[5px] bg-white shadow-7xl">
                        <div class="w-[30px] h-[30px] rounded-[5px] bg-gray-800 flex justify-center items-center">
                            <i class="fa-solid fa-star text-orange-400 text-xl"></i>
                        </div>
                        <div class="ml-[10px]">
                            <h1 class="text-xs font-semibold text-gray-800">{{ user.filiere }} - {{ user.year }}</h1>
                            <p class="text-[10px] font-bold text-gray-500">Document</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, computed, onUnmounted, onMounted } from 'vue'
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';
import axios from 'axios'
import dayjs from 'dayjs';

const router = useRouter()
const authStore = useAuthStore();
const user = computed(() => authStore.user);
const nom = user.value.firstname + ' ' + user.value.lastname

const subscriptionEndDate = user.value.subscriptionEndDate; // End date

const logout = async () => {
    isLoading.value = true
  try {
    await axios.post('/api/client/logout', {}, {
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    });
    authStore.logout();
    router.push('/');
  } catch (err) {
    if (err.response && err.response.status === 401) {
      errorMessage.value = "Session expirée. Vous êtes déconnecté automatiquement.";
      hideErrorMessageAfterDelay()
    } else {
      errorMessage.value = "Erreur lors de la déconnexion.";
      hideErrorMessageAfterDelay()
    }
  } finally {
    isLoading.value = false;
  }
};

const getTokenExpiration = (token) => {
  if (!token) return null;
  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  return tokenPayload.exp ? tokenPayload.exp * 1000 : null;
};

const tokenExpiration = getTokenExpiration(authStore.token);
if (tokenExpiration && Date.now() > tokenExpiration) {
  logout()
  router.push('/login');
}
const timeRemaining = ref({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
});

const calculateTimeRemaining = () => {
  const end = dayjs(subscriptionEndDate);
  const now = dayjs();
  const difference = end.diff(now);

  if (difference > 0) {
    timeRemaining.value.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    timeRemaining.value.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    timeRemaining.value.minutes = Math.floor((difference / 1000 / 60) % 60);
    timeRemaining.value.seconds = Math.floor((difference / 1000) % 60);
  } else {
    timeRemaining.value.days = 0;
    timeRemaining.value.hours = 0;
    timeRemaining.value.minutes = 0;
    timeRemaining.value.seconds = 0;
  }
};

let intervalId;

function goToSettingsPage() {
    router.push('/settings')
}

const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})

const errorMessage = ref('')
const documents = ref([])
const isLoading = ref(false)

const fetchdocuments = async() => {
  isLoading.value = true
  try {
      const response = await axios.get(`/api/documents/all`)
      const allDocuments = response.data;

      const filteredDocuments = allDocuments.filter(doc => {
        return doc.filiere === user.value.filiere && doc.year === user.value.year;
      });

      documents.value = filteredDocuments;
  } catch {
      errorMessage.value = "Erreur lors du chargement des documents"
      hideErrorMessageAfterDelay()
  } finally {
      isLoading.value = false
  }
}

const epreuves = ref([])
const fetchEpreuve = async() => {
  isLoading.value = true
  try {
      const response = await axios.get(`/api/epreuves`)
      const allEpreuves = response.data;

      const filteredEpreuves = allEpreuves.filter(epr => {
        return epr.filiere === user.value.filiere && epr.year === user.value.year;
      });

      epreuves.value = filteredEpreuves;
  } catch {
      errorMessage.value = "Erreur lors du chargement des épreuves"
      hideErrorMessageAfterDelay()
  } finally {
      isLoading.value = false
  }
}

const corriges = ref([])
const fetchCorriges = async() => {
  isLoading.value = true
  try {
      const response = await axios.get(`/api/exercices`)
      const allCorriges = response.data;

      const filteredCorriges = allCorriges.filter(cor => {
        return cor.filiere === user.value.filiere && cor.year === user.value.year;
      });

      corriges.value = filteredCorriges;
  } catch {
      errorMessage.value = "Erreur lors du chargement des exercices"
      hideErrorMessageAfterDelay()
  } finally {
      isLoading.value = false
  }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};


function gotoRecommandation() {
        router.push('/bibliotheques')
}
function gotoRecommandationErreur() {
        errorMessage.value = ref("Votre forfait ne vous permet pas d'avoir accès au recommandation")
        hideErrorMessageAfterDelay()
}

onMounted(() => {
    fetchdocuments()
    fetchEpreuve()
    fetchCorriges()
})

onMounted(() => {
  calculateTimeRemaining();
  intervalId = setInterval(calculateTimeRemaining, 1000); // Update every second
});

onUnmounted(() => {
  clearInterval(intervalId); // Clean up the interval when the component is destroyed
});
</script>

<style scoped>

</style>