<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <div class="flex flex-col w-[calc(100%-130px)]">
                            <h1 class="text-sm clamp font-bold text-gray-800">{{ epreuve.matiere }}</h1>
                            <p class="text-[10px] clamp font-semibold text-gray-500">
                                {{ epreuve.titre }}
                            </p>
                        </div>
                        <div class="flex items-center">
                            <button v-if="epreuve.correction" @click="gotoCorrectionPage(epreuve._id)" class="p-[10px] cursor-pointer rounded-[5px] flex justify-center text-sm fon-bold items-center bg-gray-100 shadow-7xl text-gray-600">
                                <i class="fa-solid fa-eye text-sm font-bold mr-[5px]"></i>
                                Voir le corrigé
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full parent relative flex justify-center items-center flex-col">
                <div class="w-full flex justify-center items-center relative mt-[10px]">
                    <div id="pdf-container" @contextmenu.prevent></div>
                </div>
            </div>
            <div class="w-full my-[50px] relative flex flex-col justify-center items-center">
                
                <div v-if="epreuve.correction" class="flex flex-col w-full relative items-center justify-center"> 
                    <div class="w-full my-[10px] bg-green-100 rounded-[5px] p-[10px] text-sm text-gray-700">
                        <h1 class="font-bold text-gray-800 text-center w-full">
                            Corrigé disponible !
                        </h1>
                    </div>
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import * as pdfjsLib from 'pdfjs-dist/webpack';

const router = useRouter()
const epreuveId = router.currentRoute.value.params.id;
const epreuve = ref({})
const errorMessage = ref('')
const successMessage = ref('')
const epreuveContent = ref('')

function gotoCorrectionPage(id) {
    router.push(`/epreuves/corriges/${id}`)
}

const fetchEpreuveDetail = async() => {
    try {
        const response = await axios.get(`/api/epreuves/${epreuveId}`)
        epreuve.value = response.data
        displayPDF();
    } catch (error) {
        errorMessage.value = 'Erreur lors du chargement de l\'épreuve'
        hideErrorMessageAfterDelay()
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchEpreuveDetail()
})

const displayPDF = async () => {
    const container = document.getElementById('pdf-container');
    container.innerHTML = ''; // Vide le conteneur avant de charger les pages

    const correctedPdfUrl = `https://api.revixo-learn.online/${epreuve.value.content.replace(/\\/g, '/')}`;

    try {
        const pdf = await pdfjsLib.getDocument(correctedPdfUrl).promise;
        const containerWidth = container.clientWidth;
        const pixelRatio = window.devicePixelRatio || 1; // Récupère la densité de pixels de l'écran

        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);

            // Calculer l'échelle pour s'adapter à la largeur du conteneur
            const viewport = page.getViewport({ scale: 1 });
            const scale = containerWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Ajuster la résolution pour une meilleure netteté
            canvas.height = scaledViewport.height * pixelRatio;
            canvas.width = scaledViewport.width * pixelRatio;
            canvas.style.height = `${scaledViewport.height}px`;  // Taille CSS
            canvas.style.width = `${scaledViewport.width}px`;    // Taille CSS

            // Désactive le clic droit et la sélection de texte
            canvas.style.pointerEvents = 'none';
            canvas.style.userSelect = 'none';

            // Rendre la page sur le canvas en tenant compte du ratio de pixels
            context.scale(pixelRatio, pixelRatio);
            await page.render({ canvasContext: context, viewport: scaledViewport }).promise;

            container.appendChild(canvas); // Ajouter le canvas après le rendu complet de la page
        }
    } catch (error) {
        errorMessage.value = 'Erreur lors de l\'affichage du PDF';
        hideErrorMessageAfterDelay();
    }
};

</script>

<style scoped>
#pdf-container {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
canvas {
  pointer-events: none;
  width: 100%;
}
</style>