<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex flex-col justify-sttart rounded-[10px] items-center">
                <h1 class="text-center text-gray-800 font-bold ">Vous avez besoin <span class="text-red-600 underline decoration-red-500">d'aide ?</span> </h1>
                <div class="w-full relative my-[20px]">
                    <input type="text" v-model="searchQuery" class="w-full p-[10px] rounded-[5px] outline-none border bg-transparent text-sm" placeholder="Recherchez une solution à votre problème">
                </div>
                <div v-for="(faq, index) in filteredFaqs" :key="index" class="w-full bg-white cursor-pointer my-[10px] relative border rounded-[20px] overflow-hidden p-[20px]">
                    <div @click="toggleContent(faq._id)" class="w-full flex justify-between items-center relative">
                        <h1 class="text-sm font-bold text-gray-800">{{ faq.question }}</h1>
                        <button>
                            <i v-if="showContent === faq._id" class="fa-solid fa-chevron-up duration-200"></i>
                            <i v-else class="fa-solid fa-chevron-down duration-200"></i>
                        </button>
                    </div>
                    <div v-if="showContent === faq._id" class="w-full border-t mt-[10px] pt-[10px] relative">
                        <p class="text-sm text-gray-600 font-semibold">
                            {{ faq.reponse }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="filteredFaqs.length === 0" class="w-full h-[200px] flex flex-col justify-center items-center relative">
                <h1 class="text-center text-gray-800 font-bold text-sm">
                    Vous n'avez pas eu de réponse à votre problème ?!
                </h1>
                <a href="tel:+22943742700" class="text-sm font-bold text-white bg-blue-950 p-[10px] rounded-[5px] mt-[15px]">Nous contacter</a>
            </div>

        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'

const faqs = ref([])
const isLoading = ref(false)
const errorMessage = ref('')
const successMessage = ref('')
const showContent = ref(null)

function toggleContent(id) {
    showContent.value = showContent.value === id ? null : id
}

const fetchFAQ = async () => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/faqs')
        faqs.value = response.data
    } catch (error) {
        errorMessage.value = 'Erreur lors du chargement des informations de la FAQ'
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

const searchQuery = ref('');

const filteredFaqs = computed(() => {
    const query = searchQuery.value.toLowerCase();
    return faqs.value.filter(faq => 
        faq.question.toLowerCase().includes(query) || 
        faq.reponse.toLowerCase().includes(query)
    );
});

onMounted(() => {
    fetchFAQ()
})
</script>

<style scoped>
</style>