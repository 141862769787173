<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-between items-center">
                <div class="flex flex-col">
                    <h1 class="text-blue-950 md:text-3xl text-lg">Bienvenue Mickaël !</h1>
                    <p class="text-sm text-gray-500 ">Ici vous trouverez vos matières en détails</p>
                </div>
            </div>
            <div class="w-full relative my-[10px]">
                <label for="" class="absolute top-[10px] right-[10px] text-gray-800 font-bold text-sm"><i class="fa-solid fa-search"></i></label>
                <input type="text" class="w-full rounded-[5px] p-[10px] text-sm outline-none border border-gray-300 bg-transparent" placeholder="Recherchez une matière">
            </div>
            <div class="flex flex-wrap md:justify-between justify-center items-center w-full relative">
                <div class="w-[100%] md:w-[calc(100%/2-10px)] px-[20px] items-start py-[25px] my-[10px] bg-blue-100 rounded-[20px] h-[140px]">
                    <div class="">
                        <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Statistique</h1>
                        <h1 class="text-gray-700 font-bold text-sm mb-[10px]">1er Année</h1>
                        <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Statistique et Probabilité</h1>
                    </div>
                    <div class="w-6/12">
                        <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Science</h1>
                        <p class="text-sm font-semibold text-gray-500">
                            <span>Epreuves : </span> 24
                        </p>
                        <p class="text-sm font-semibold text-gray-500">
                            <span>Corrigés : </span> 34
                        </p>
                        <p class="text-sm font-semibold text-gray-500">
                            <span>Documents : </span> 3
                        </p>
                    </div>
                </div>
                <div class="w-[100%] md:w-[calc(100%/2-10px)] px-[20px] py-[25px] my-[10px] bg-gray-200 rounded-[20px] h-[140px]">
                    <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Science</h1>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Epreuves : </span> 24
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Corrigés : </span> 34
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Documents : </span> 3
                    </p>
                </div>
                <div class="w-[100%] md:w-[calc(100%/2-10px)] px-[20px] py-[25px] my-[10px] bg-teal-100 rounded-[20px] h-[140px]">
                    <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Science</h1>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Epreuves : </span> 24
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Corrigés : </span> 34
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Documents : </span> 3
                    </p>
                </div>
                <div class="w-[100%] md:w-[calc(100%/2-10px)] px-[20px] py-[25px] my-[10px] bg-orange-100 rounded-[20px] h-[140px]">
                    <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Science</h1>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Epreuves : </span> 24
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Corrigés : </span> 34
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Documents : </span> 3
                    </p>
                </div>
                <div class="w-[100%] md:w-[calc(100%/2-10px)] px-[20px] py-[25px] my-[10px] bg-red-100 rounded-[20px] h-[140px]">
                    <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Science</h1>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Epreuves : </span> 24
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Corrigés : </span> 34
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Documents : </span> 3
                    </p>
                </div>
                <div class="w-[100%] md:w-[calc(100%/2-10px)] px-[20px] py-[25px] my-[10px] bg-cyan-100 rounded-[20px] h-[140px]">
                    <h1 class="text-gray-700 font-bold text-sm mb-[10px]">Science</h1>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Epreuves : </span> 24
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Corrigés : </span> 34
                    </p>
                    <p class="text-sm font-semibold text-gray-500">
                        <span>Documents : </span> 3
                    </p>
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref } from 'vue'
const filtre = ref(true)

function toggleFiltre() {
    filtre.value = !filtre.value
}
</script>

<style>

</style>    