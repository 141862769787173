<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <h1 class="text-sm font-bold ">Liste des corrigés</h1>
                        <div class="flex items-center">
                            <div class="w-[300px] md:max-w-[300px] max-w-[180px] mr-[15px] relative">
                                <label for="" class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700"><i class="fa-solid fa-search"></i></label>
                                <input type="text" placeholder="Recherchez une épreuve" class="w-full p-[10px] pr-[30px] bg-transparent border border-gray-300 rounded-[5px] text-sm outline-none">
                            </div>
                            <div @click="toggleFiltre()" class="w-[35px] h-[35px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-gray-600">
                                <i class="fa-solid fa-filter text-sm font-bold"></i>
                            </div>
                        </div>
                    </div>
                    <div v-if="filtre" class="w-full relative flex items-center my-[10px]">
                        <div class="w-full relative">
                            <select name="" id="" class="w-full p-[10px] rounded-[5px] text-sm outline-none border border-gray-300 bg-transparent text-gray-600">
                                <option value="">Statistique et Application</option>
                                <option value="">Statistique et Application</option>
                                <option value="">Statistique et Application</option>
                                <option value="">Statistique et Application</option>
                            </select>
                        </div>
                        <div class="flex items-center ml-[10px]">
                            <button class="p-[10px] text-sm font-semibold text-center border border-gray-300 text-gray-800 bg-gray-200 rounded-[5px] mx-[5px]">2021</button>
                            <button class="p-[10px] text-sm font-semibold text-center border border-gray-300 text-gray-800 bg-gray-200 rounded-[5px] mx-[5px]">2022</button>
                            <button class="p-[10px] text-sm font-semibold text-center border border-gray-300 text-gray-800 bg-gray-200 rounded-[5px] mx-[5px]">2023</button>
                        </div>
                    </div>
                </div>
                <router-link v-for="(epreuve, index) in epreuves" :key="index" class="w-full md:px-[20px] px-[10px] cursor-pointer my-[5px] py-[10px] rounded-[5px] bg-white flex justify-between items-center" :to="'/corriges/'+ epreuve.id">
                    <div class="flex items-center md:w-auto w-[calc(100%-80px)]">
                        <div class="w-[35px] h-[40px] min-w-[35px] min-h-[40px] relative overflow-hidden flex justify-center items-center">
                            <img :src="epreuve.image" alt="" class="w-full h-full absolute object-contain">
                        </div>
                        <div class="flex flex-col ml-[10px]">
                            <h1 class="text-sm clamp font-bold text-gray-800">{{ epreuve.matiere }}</h1>
                            <p class="text-[10px] clamp font-semibold text-gray-500">
                                {{ epreuve.nom }}
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="p-[10px] rounded-[5px] bg-gray-100 flex text-[10px] font-bold text-gray-600">
                            <div class="hidden md:flex items-center">
                                <i class="fa-solid fa-calendar pr-[5px]"></i>
                                <span>{{ epreuve.recommandation }}</span>
                            </div>
                            <span class="mx-[5px] hidden md:flex">|</span>
                            <div class="flex items-center">
                                <i class="fa-solid fa-star pr-[5px]"></i>
                                <span>{{ epreuve.star }}</span>
                            </div>
                        </div>
                        <i class="fa-solid fa-arrow-right ml-[10px] text-sm"></i>
                    </div>
                </router-link>
                <div class="w-full relative flex justify-center md:justify-end items-center mt-[20px]">
                    <div class="w-[30px] h-[30px] rounded-[5px] bg-blue-950 flex justify-center items-center m-[10px] text-sm font-bold text-white shadow-9xl">1</div>
                    <div class="w-[30px] h-[30px] rounded-[5px] bg-gray-100 flex justify-center items-center m-[10px] text-sm font-bold text-gray-800 shadow-9xl">2</div>
                    <div class="w-[30px] h-[30px] rounded-[5px] bg-gray-100 flex justify-center items-center m-[10px] text-sm font-bold text-gray-800 shadow-9xl">...</div>
                    <div class="w-[30px] h-[30px] rounded-[5px] bg-gray-100 flex justify-center items-center m-[10px] text-sm font-bold text-gray-800 shadow-9xl"><i class="fa-solid fa-chevron-right"></i></div>
                </div>
            </div>
            <div class="w-full relative flex justify-center items-center flex-col">

            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref } from 'vue'
const filtre = ref(true)

function toggleFiltre() {
    filtre.value = !filtre.value
}

const epreuves = ref([
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
    {
        id: 12455,
        matiere: "Statistique inférentielle et application",
        nom: "Corrigé Statistique 2022-2023 session normale",
        recommandation: "22/07/2024",
        star: 5,
        image: "./img/epreuve.jpeg"
    },
])
</script>

<style>

</style>