<template>
    <footer class="w-full py-[50px] bg-gray-800 flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-center items-center">
            <div class="w-full flex relative justify-between items-center">
                <div class="w-full my-[10px]">
                    <h1 class="text-3xl text-white uppercase roboto-bold">Revixo</h1>
                    <p class="text-sm text-gray-200 roboto-light uppercase">Tout pour réviser !!</p>
                </div>
                <div class="flex">
                    <div class="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-white text-gray-800 font-bold text-sm m-[10px]">
                        <a href="https://www.facebook.com/profile.php?id=61566924420082" target="_blank">
                            <i class="fa-brands fa-facebook"></i>
                        </a>
                    </div>
                    <div class="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-white text-gray-800 font-bold text-sm m-[10px]">
                        <a href="https://wa.me/+22943742700" target="_blank">
                            <i class="fa-brands fa-whatsapp"></i>
                        </a>
                    </div>
                    <div class="w-[30px] h-[30px] rounded-[5px] flex justify-center items-center bg-white text-gray-800 font-bold text-sm m-[10px]">
                        <a href="mailto:revixolearn@gmail.com" target="_blank">
                            <i class="fa-solid fa-envelope"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="w-full p-[20px] bg-white flex justify-center items-center relative">
        <p class="text-gray-800 text-xs text-center">
            &copy; Copyright Revixo, Tous droits réservés | Développé par <a href="tel:+22941787700" class="hover:underline duration-100 font-bold">Vaybe</a>
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>