<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full relative h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full lg:h-[200px] h-[350px] p-[20px] md:p-[0px] flex-col lg:flex-row bg-white relative flex justify-center overflow-hidden items-center rounded-[25px]">
                <div class="lg:w-6/12 w-full h-full p-[20px] flex flex-col justify-center items-center">
                    <h1 class="text-sm font-bold text-center text-gray-800">Découvrez notre Bibliothèque</h1>
                    <p class="text-xs font-semibold mt-[10px] text-center lg:text-center">
                        Nous mettons à votre disposition une large sélection de documents de cours et de ressources culturelles, pour vous accompagner efficacement dans la préparation et la réussite de vos examens.
                    </p>
                </div>
                <div class="lg:w-6/12 w-full h-full flex flex-col justify-center items-center relative overflow-hidden">
                    <img src="../assets/images/biblio.jpg" alt="" class="w-full h-full absolute object-contain">
                </div>
            </div>
            <div class="my-[50px] w-full relative flex justify-center items-center flex-col">
                <div class="w-full relative flex justify-between items-center">
                    <h1 class="text-sm font-bold text-gray-800">Livres recommandés</h1>
                    <router-link to="/bibliotheques/tous-les-livres" class="text-orange-600 font-bold text-sm hover:underline">Voir tout</router-link>
                </div>
                <div class="mt-[10px] flex w-full relative md:flex-row flex-col flex-wrap justify-center md:justify-between items-center">
                    <router-link
                      v-for="(document, index) in documents"
                      :key="index"
                      :to="document.isActive ? '/bibliotheques/livres/' + document._id : '#'"
                      :class="['md:w-[calc(100%/2-10px)] w-full relative h-[240px] my-[10px] rounded-[10px] shadow-4xl overflow-hidden', { 'cursor-not-allowed opacity-50': !document.isActive }]"
                      :style="!document.isActive ? 'filter: blur(3px); pointer-events: none;' : ''"
                    >
                      <div class="w-full h-[150px] flex justify-center bg-white items-center relative overflow-hidden">
                        <img
                          src="../assets/images/docs.jpg"
                          :alt="document.titre"
                          class="w-full h-full absolute object-contain object-top"
                        />
                      </div>
                      <div class="py-[20px] px-[10px]">
                        <h1 class="text-sm font-bold text-gray-800 line-clamp-2">{{ document.titre }}</h1>
                        <div class="flex absolute w-full bottom-0 left-0 px-[10px] justify-between items-center">
                          <div class="text-xs text-blue-600 py-[10px] flex items-center">
                            {{ formatDate(document.createdAt) }} - Administrateur
                          </div>
                          <div class="flex relative mt-[5px] text-gray-800 text-xs items-center">
                            <i class="fa-solid fa-star mr-[5px]"></i>
                            <i class="fa-solid fa-star mr-[5px]"></i>
                            <i class="fa-solid fa-star mr-[5px]"></i>
                            <i class="fa-solid fa-star mr-[5px]"></i>
                            <i class="fa-solid fa-star mr-[5px]"></i>
                          </div>
                        </div>
                      </div>
                    </router-link>
                </div>
            </div>
            <div v-if="!isLoading && documents.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucun document trouvé.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner"></div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, onMounted, computed } from 'vue'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const router = useRouter()
const isLoading = ref(false)
const documents = ref([])
const errorMessage = ref('')
const successMessage = ref('')

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR'); 
}

const fetchdocuments = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/documents/all')
        const alldocuments = response.data;

        const filtereddocuments = alldocuments.filter(epr => {
            return epr.filiere === user.value.filiere && epr.year === user.value.year && epr.isRecommande;
        });

        documents.value = filtereddocuments;
    } catch {
        errorMessage.value = "Erreur lors du chargement des Documents"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchdocuments()
})
const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
        //router.push('/dashboard')
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})
</script>

<style>

</style>    