import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../store/auth';
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import EpreuvesView from '@/views/EpreuvesView.vue'
import DetailEpreuveView from '@/views/DetailEpreuveView.vue'
import CorrigesView from '@/views/CorrigesView.vue'
import DetailCorrigesView from '@/views/DetailCorrigesView.vue'
import ExerciceView from '@/views/ExerciceView.vue'
import DetailExerciceView from '@/views/DetailExerciceView.vue'
import MatiereView from '@/views/MatiereView.vue'
import BiblioView from '@/views/BiblioView.vue'
import BiblioAllView from '@/views/BiblioAllView.vue'
import SeeLivreView from '@/views/SeeLivreView.vue'
import RegisterView from '@/views/RegisterView.vue'
import VerifyView from '@/views/VerifyView.vue';
import SettingView from '@/views/SettingView.vue';
import HelpView from '@/views/HelpView.vue';
import NewsView from '@/views/NewsView.vue';
import ResetView from '@/views/ResetView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
    meta: { requiresAuth: false },
  },
  {
    path: '/verify',
    name: 'Verify',
    component: VerifyView,
    meta: { requiresAuth: false },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/epreuves',
    name: 'epreuves',
    component: EpreuvesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/epreuves/:id',
    name: 'details epreuves',
    component: DetailEpreuveView,
    meta: { requiresAuth: true },
  },
  {
    path: '/corriges',
    name: 'corriges',
    component: CorrigesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/epreuves/corriges/:id',
    name: 'details corriges',
    component: DetailCorrigesView,
    meta: { requiresAuth: true },
  },
  {
    path: '/exercices',
    name: 'exercices',
    component: ExerciceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/exercices/:id',
    name: 'details exercices',
    component: DetailExerciceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/matieres-horsligne',
    name: 'matieres',
    component: MatiereView,
    meta: { requiresAuth: true },
  },
  {
    path: '/bibliotheques',
    name: 'bibliotheques',
    component: BiblioView,
    meta: { requiresAuth: true },
  },
  {
    path: '/bibliotheques/tous-les-livres',
    name: 'tous les livres',
    component: BiblioAllView,
    meta: { requiresAuth: true },
  },
  {
    path: '/bibliotheques/livres/:id',
    name: 'livre',
    component: SeeLivreView,
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingView,
    meta: { requiresAuth: true },
  },
  {
    path: '/helps',
    name: 'helps',
    component: HelpView,
    meta: { requiresAuth: true },
  },
  {
    path: '/actualites',
    name: 'actualites',
    component: NewsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetView,
    meta: { requiresAuth: false },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router
