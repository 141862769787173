<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section id="windows" class="bg-blue-50 parent w-full relative h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="mb-[25px] w-full relative flex flex-col">
                <div class="w-full relative flex my-[10px] justify-between items-center">
                    <h1 class="text-sm font-bold ">Tous les livres</h1>
                    <div class="flex items-center">
                        <!-- Title Search Input -->
                        <div class="w-[300px] md:max-w-[300px] max-w-[180px] mr-[15px] relative">
                            <label for="search" class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700">
                                <i class="fa-solid fa-search"></i>
                            </label>
                            <input v-model="searchQuery" @input="searchDocuments" id="search" type="text" placeholder="Recherchez une épreuve" 
                                class="w-full p-[10px] pr-[30px] bg-transparent border border-gray-300 rounded-[5px] text-sm outline-none">
                        </div>
                        <div @click="toggleFiltre" class="w-[35px] h-[35px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-gray-600">
                            <i class="fa-solid fa-filter text-sm font-bold"></i>
                        </div>
                    </div>
                </div>

                <div v-if="filtre" class="w-full relative flex items-center my-[10px]">
                    <!-- Subject Filter -->
                    <div class="w-full relative">
                        <select v-model="selectedMatiere" @change="searchDocuments" class="w-full p-[10px] rounded-[5px] text-sm outline-none border border-gray-300 bg-transparent text-gray-600">
                            <option value="">Toutes les matières</option>
                            <option v-for="matiere in uniqueMatieres" :key="matiere" :value="matiere">{{ matiere }}</option>
                        </select>
                    </div>
                    <!-- Year Filter    
                    <div class="w-full relative ml-[10px]">
                        <select v-model="selectedYear" @change="searchDocuments" class="w-full p-[10px] rounded-[5px] text-sm outline-none border border-gray-300 bg-transparent text-gray-600">
                            <option value="">Toutes les années</option>
                            <option v-for="year in uniqueYears" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                    -->
                </div>
            </div>
            <div class="my-[20px] w-full relative flex justify-center items-center flex-col">
                <div class="mt-[10px] flex w-full relative md:flex-row flex-col justify-center flex-wrap md:justify-between items-center">
                    <router-link
                      v-for="(document, index) in paginateddocuments"
                      :key="index"
                      :to="document.isActive ? '/bibliotheques/livres/' + document._id : '#'"
                      :class="['md:w-[calc(100%/2-10px)] w-full relative h-[240px] my-[10px] rounded-[10px] shadow-4xl overflow-hidden', { 'cursor-not-allowed opacity-50': !document.isActive }]"
                      :style="!document.isActive ? 'filter: blur(3px); pointer-events: none;' : ''"
                    >
                        <div class="w-full h-[150px] flex justify-center items-center relative bg-white overflow-hidden">
                            <img src="../assets/images/docs.jpg" :alt="document.titre" class="w-full h-full absolute object-contain object-top">
                        </div>
                        <div class="py-[20px] px-[10px]">
                            <h1 class="text-sm font-bold text-gray-800 line-clamp-2">{{ document.titre }}</h1>
                            <div class="flex absolute w-full bottom-0 left-0 px-[10px] justify-between items-center ">
                                <div class=" text-xs text-blue-600 py-[10px] flex items-center">
                                    {{ formatDate(document.createdAt) }} - Administrateur
                                </div>
                                <div class="flex relative mt-[5px] text-gray-800 text-xs items-center">
                                    <i class="fa-solid fa-star mr-[5px]"></i>
                                    <i class="fa-solid fa-star mr-[5px]"></i>
                                    <i class="fa-solid fa-star mr-[5px]"></i>
                                    <i class="fa-solid fa-star mr-[5px]"></i>
                                    <i class="fa-solid fa-star mr-[5px]"></i>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div v-if="paginateddocuments.length !== 0" class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
                    <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                    :class="{'bg-blue-950 text-white border border-blue-950': currentPage === page, 'bg-white': currentPage !== page}" 
                    class="w-[30px] h-[30px] mx-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                        {{ page }}
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && paginateddocuments.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucun document trouvé.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner"></div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { onMounted, ref, computed } from 'vue'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'
import { useRouter } from 'vue-router';

const router = useRouter()
const authStore = useAuthStore();
const user = computed(() => authStore.user);

const filtre = ref(true)

function toggleFiltre() {
    filtre.value = !filtre.value
}


const isLoading = ref(false)
const documents = ref([])
const errorMessage = ref('')
const successMessage = ref('')

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR'); 
}

const fetchdocuments = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/documents/all')
        const alldocuments = response.data;

        const filtereddocuments = alldocuments.filter(epr => {
            return epr.filiere === user.value.filiere && epr.year === user.value.year;
        });

        documents.value = filtereddocuments;
    } catch {
        errorMessage.value = "Erreur lors du chargement des Documents"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const searchQuery = ref('');
const selectedMatiere = ref('');
const selectedYear = ref('');

// Extract unique years and subjects from documents
const uniqueMatieres = computed(() => [...new Set(documents.value.map(doc => doc.matiere))]);
const uniqueYears = computed(() => [...new Set(documents.value.map(doc => doc.year))]);
const filteredDocs = computed(() => {
    return documents.value.filter(ducument => {
        const matchesMatiere = selectedMatiere.value ? ducument.matiere.includes(selectedMatiere.value) : true;
        const matchesYear = selectedYear.value ? ducument.year === selectedYear.value : true;
        const matchesSearch = ducument.titre.toLowerCase().includes(searchQuery.value.toLowerCase());
        return matchesMatiere && matchesYear && matchesSearch;
    });
});
const currentPage = ref(1);
const itemsPerPage = ref(20);
const totalPages = computed(() => Math.ceil(filteredDocs.value.length / itemsPerPage.value));

const goToPage = (pageNumber) => {
  if (pageNumber >= 1 && pageNumber <= totalPages.value) {
    currentPage.value = pageNumber;
  }
  scrollToTop()
};

const paginateddocuments = computed(() => {
  let filteredDocs = documents.value || []; // Default to an empty array if undefined

  // Apply search filters
  if (searchQuery.value) {
    filteredDocs = filteredDocs.filter(doc => doc.titre.toLowerCase().includes(searchQuery.value.toLowerCase()));
  }
  if (selectedYear.value) {
    filteredDocs = filteredDocs.filter(doc => doc.year.includes(selectedYear.value));
  }
  if (selectedMatiere.value) {
    filteredDocs = filteredDocs.filter(doc => doc.matiere === selectedMatiere.value);
  }

  // Ensure start and end indices are valid
  const start = Math.max((currentPage.value - 1) * itemsPerPage.value, 0);
  const end = start + itemsPerPage.value;

  return filteredDocs.slice(start, end); // Return paginated documents or an empty array
});

const searchDocuments = () => {
  currentPage.value = 1; // Reset to the first page when filters change
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
        //router.push('/dashboard')
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})
const scrollToTop = () => {
    const windows = document.getElementById("windows")
    windows.scrollTo({
        top: -0,
        behavior: 'smooth'
    })
}
onMounted(() => {
    fetchdocuments()
})
</script>

<style>

</style>    