<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex lg:flex-row flex-col justify-center lg:h-[100%] h-auto rounded-[10px] shadow-4xl items-start">
                <div class="lg:w-[30%] w-full bg-gray-50 lg:h-full h-auto relative">
                    <div class="w-full h-[180px] p-[20px] lg:flex hidden justify-center items-center relative">
                        <div class="w-full h-full relative rounded-[20px] shadow-4xl overflow-hidden flex justify-center items-center">
                            <img src="../assets/images/icone.png" alt="" class="w-full h-full absolute object-cover">
                            <div class="w-full h-full absolute top-0 bg-gray-800/60 flex flex-col justify-center items-center"></div>
                        </div>
                    </div>
                    <div class="w-full lg:mt-[10px] flex flex-row lg:flex-col">
                        <div @click="toggleInformation()" class="p-[10px] w-[calc(100%/3)] lg:w-full text-sm cursor-pointer font-bold duration-200 text-gray-600 flex flex-col md:flex-row justify-center lg:justify-start items-center" :class="{'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-blue-950': isInformation, 'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-gray-50': !isInformation}">
                            <i class="fa-solid fa-user w-[25px] h-[20px] lg:h-auto text-left flex justify-start"></i>
                            <h1>Information</h1>
                        </div>
                        <div @click="togglePassword()" class="p-[10px] w-[calc(100%/3)] lg:w-full text-sm cursor-pointer font-bold duration-200 text-gray-600 flex flex-col md:flex-row justify-center lg:justify-start items-center" :class="{'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-blue-950': isPassword, 'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-gray-50': !isPassword}">
                            <i class="fa-solid fa-lock w-[25px] h-[20px] lg:h-auto text-left flex justify-start"></i>
                            <h1>Mot de passe</h1>
                        </div>
                        <div @click="toggleAbonnement()" class="p-[10px] w-[calc(100%/3)] lg:w-full text-sm cursor-pointer font-bold duration-200 text-gray-600 flex flex-col md:flex-row justify-center lg:justify-start items-center" :class="{'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-blue-950': isAbonnement1, 'lg:border-l-[5px] border-t-[5px] lg:border-t-0 border-gray-50': !isAbonnement1 }">
                            <i class="fa-solid fa-tags w-[25px] h-[20px] lg:h-auto text-left flex justify-start"></i>
                            <h1>Abonnement</h1>
                        </div>
                    </div>
                </div>
                <div class="lg:w-[70%] w-full bg-white p-[20px] h-full flex flex-col relative overflow-y-auto parent">
                    <h1 v-if="isInformation" class="text-sm md:text-2xl font-bold text-gray-800">Informations personnelles</h1>
                    <form v-if="isInformation" @submit.prevent="submitForm()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="nom" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Nom">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="prenoms" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Prénoms">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="email" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Email">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="text" required v-model="phone" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Numéro">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Modifier les Informations</span>
                                <div v-if="isLoading" class="spinner"></div>
                            </button>
                        </div>
                    </form>
                    <h1 v-if="isPassword" class="text-sm md:text-2xl font-bold text-gray-800">Mot de passe</h1>
                    <form v-if="isPassword" @submit.prevent="submitFormPassword()" class="w-full flex py-[10px] relative flex-col">
                        <div class="my-[10px] w-full relative">
                            <input type="password" required v-model="Opassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Ancien mot de passe">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="password" required v-model="Npassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Nouveau mot de passe">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <input type="password" required v-model="CNpassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full" placeholder="Confirmer le nouveau mot de passe">
                        </div>
                        <div class="my-[10px] w-full relative">
                            <button class="p-[10px] rounded-[5px] border h-[45px] flex justify-center items-center bg-gray-800 text-gray-100 text-sm outline-none w-full">
                                <span v-if="!isLoading">Modifier le mot de passe</span>
                                <div v-if="isLoading" class="spinner"></div>
                            </button>
                        </div>
                    </form>
                    <h1 v-if="isAbonnement1" class="text-sm md:text-2xl font-bold text-gray-800">Abonnement</h1>
                    <div v-if="isAbonnement1" class="w-full h-full min-h-[250px] flex flex-col justify-center items-center">
                        <button @click="toggleAbonnement1()" class="bg-blue-950 text-white font-bold text-sm rounded-[5px] p-[10px]">
                            Faire un Abonnement
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <div v-if="isAbonne" class="bg-black/80 z-[100000] w-full h-full top-0 left-0 fixed flex justify-center items-center">
            <div class="md:h-[80%] md:w-[80%] p-[20px] md:p-[50px] pt-[50px] w-[100%] h-[100%] bg-white flex flex-col items-center relative shadow-4xl rounded-[20px] overflow-y-auto parent">
                <div class="w-full fixed p-[10px] md:top-[10px] top-0 bg-white md:bg-transparent z-20 flex justify-center items-center">
                    <button @click="toggleAbonnement1()" class="w-[30px] h-[30px] rounded-[50%] bg-gray-100 flex justify-center items-center relative text-sm font-bold text-gray-800">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <h1 class="text-2xl font-bold text-blue-950 text-center">Salut {{ allName }}</h1>
                <p class="my-[10px] text-center max-w-[600px] px-[20px] text-sm font-semibold text-gray-600">
                    Voulez vous Renouveler votre abonnement ou allez à un abonnement supérieur ?! 
                </p>
                <div class="flex w-full relative mt-[50px] md:justify-start justify-center flex-wrap items-center">
                    <div class="md:w-[200px] md:mb-0 mb-[20px] w-full flex items-center justify-center">
                        <h1 v-if="isStandar" class="text-2xl font-bold text-gray-800 w-full text-center">
                            Passez au Premium pour 2000F
                        </h1>
                        <h1 v-else class="text-2xl font-bold text-gray-800 w-full text-center">
                            Choisissez un plan
                        </h1>
                    </div>
                    <div @click="selectPlan('Standar')" class="md:w-[300px] w-[340px] cursor-pointer h-auto relative min-w-[300px] my-[10px] mx-0 md:mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': isSelected == 'Standar', 'shadow-4xl': isSelected != 'Standar'}">
                        <div class="w-full h-[150px] rounded-[20px] flex flex-col justify-center items-center relative overflow-hidden">
                            <div class="w-[120px] h-[120px] relative flex justify-center items-center overflow-hidden">
                                <img src="../assets/images/standar.jpeg" alt="" >
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mt-[10px]">Standard</h1>
                        </div>
                        <div class="w-full p-[20px]">
                            <ul>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Epreuves et Corrigés</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Recommandations périodiques</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Actualités et informations</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-xmark text-red-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Bibliothèque</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-xmark text-red-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Exercices Pratiques Corrigés</p>
                                </li>
                            </ul>
                        </div>
                        <div v-if="isSelected == 'Standar'" class="w-[30px] h-[30px] rounded-[50%] bg-blue-200 border-[5px] border-blue-600 absolute z-40 right-[20px] top-[20px]"></div>
                    </div>
                    <div @click="selectPlan('Premium')" class="md:w-[300px] w-[340px] cursor-pointer h-auto relative min-w-[300px] my-[10px] mx-0 md:mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': isSelected == 'Premium', 'shadow-4xl': isSelected != 'Premium'}">
                        <div class="w-full h-[150px] rounded-[20px] flex flex-col justify-center items-center relative overflow-hidden">
                            <div class="w-[120px] h-[120px] relative flex justify-center items-center overflow-hidden">
                                <img src="../assets/images/premium.jpeg" alt="" >
                            </div>
                            <h1 class="text-sm font-bold text-gray-800 mt-[10px]">Premium</h1>
                        </div>
                        <div class="w-full p-[20px]">
                            <ul>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Epreuves et Corrigés</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Recommandations périodiques</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Actualités et informations</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Bibliothèque</p>
                                </li>
                                <li class="flex items-center my-[5px]">
                                    <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                    <p class="text-sm font-semibold text-gray-600">Exercices Pratiques Corrigés</p>
                                </li>
                            </ul>
                        </div>
                        <div v-if="isSelected == 'Premium'" class="w-[30px] h-[30px] rounded-[50%] bg-blue-200 border-[5px] border-blue-600 absolute z-40 right-[20px] top-[20px]"></div>
                    </div>
                </div>
                <div v-if="isSelected == 'Premium'" class="flex w-full relative mt-[50px] md:justify-start justify-center flex-wrap items-center">
                    <div class="md:w-[200px] md:mb-0 mb-[20px] w-full flex items-center justify-center">
                        <h1 class="text-2xl font-bold text-gray-800 w-full text-center">
                            Choisissez un forfait
                        </h1>
                    </div>
                    <div v-if="isStandar" @click="selectForfait(2000)" class="w-[120px] cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 2000, 'shadow-4xl': forfaitSelected != 2000}">
                        
                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                2000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 2000" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                    <div v-else @click="selectForfait(5000)" class="w-[120px] cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 5000, 'shadow-4xl': forfaitSelected != 5000}">
                        
                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                5000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 5000" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                    <div disabled style="filter: blur(10px); pointer-events: none;" class="w-[120px] bl cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 500, 'shadow-4xl': forfaitSelected != 500}">
                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                500 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 500" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                    <div @click="selectForfait(500)" class="w-[120px] hidden cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 500, 'shadow-4xl': forfaitSelected != 500}">
                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                500 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 500" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                </div>
                <div v-if="isSelected == 'Standar'" class="flex w-full relative mt-[50px] md:justify-start justify-center flex-wrap items-center">
                    <div class="md:w-[200px] md:mb-0 mb-[20px] w-full flex items-center justify-center">
                        <h1 class="text-2xl font-bold text-gray-800 w-full text-center">
                            Choisissez un forfait
                        </h1>
                    </div>
                    <div @click="selectForfait(3000)" class="w-[120px] cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 3000, 'shadow-4xl': forfaitSelected != 3000}">

                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                3000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 3000" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                    <div disabled style="filter: blur(10px); pointer-events: none;" class="w-[120px] bl cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 500, 'shadow-4xl': forfaitSelected != 500}">
                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                300 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 300" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                    <div @click="selectForfait(300)" class="w-[120px] hidden cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 300, 'shadow-4xl': forfaitSelected != 300}">
                        <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                            <h1 class="text-3xl font-bold text-gray-800 text-center">
                                300 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                            </h1>
                        </div>
                        <div v-if="forfaitSelected == 300" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                    </div>
                </div>
                <div class="w-full bg-white rounded-[10px] mt-[20px] py-[20px]">
                    <h1 class="text-xl font-bold text-gray-800 pb-[20px] border-b">Paiement</h1>
                    <div class="w-full flex py-[10px]">
                        <div @click="selectPayment('moov')" :class="{'border-blue-500 border-2': selectedPayment === 'moov'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl rounded-[5px] flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/moov.png" alt="Moov">
                        </div>
                        <div @click="selectPayment('mtn')" :class="{'border-blue-500 border-2': selectedPayment === 'mtn'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl mx-[20px] rounded-[5px] flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/mtn.png" alt="MTN">
                        </div>
                        <div @click="selectPayment('mastercard')" :class="{'border-blue-500 border-2': selectedPayment === 'mastercard'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl rounded-[5px] flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/master.png" alt="Mastercard">
                        </div>
                    </div>
                
                    <div v-if="selectedPayment === 'mastercard' || selectedPayment === 'visa'" class="mt-[20px]">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Numéro de Carte</label>
                        <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="1234 5678 9123 4567">
                        <div class="w-full relative flex justify-between items-center">
                            <div class="w-[70%] relative">
                                <label class="block mt-[10px] mb-2 text-sm font-medium text-gray-900">Expiration</label>
                                <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="MM/AA">
                            </div>
                            <div class="w-[28%] relative">
                                <label class="block mt-[10px] mb-2 text-sm font-medium text-gray-900">CVC</label>
                                <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="123">
                            </div>
                        </div>
                    </div>
                
                    <div v-if="selectedPayment === 'moov' || selectedPayment === 'mtn'" class="mt-[20px]">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Numéro de Téléphone</label>
                        <input type="text" v-model="numeroPaiement" class="w-full p-2 border rounded-[5px] outline-none" placeholder="Entrez votre numéro">
                    </div>
                </div>
                <div class="w-full h-auto bg-white py-[20px]">
                    <h1 class="text-gray-800 text-xl mb-[20px] font-bold relative">Résumé d'achat</h1>
                    <div class="w-full relative">
                        <div v-if="isSelected == 'Standar'" class="w-full relative flex justify-between my-[10px] items-center">
                            <h1 class="text-xs font-semibold text-gray-500">Standard:</h1>
                            <p class="text-sm font-bold text-gray-700"> <span class="text-[10px]">{{ forfaitSelected ? forfaitSelected : 0 }} FCFA</span></p>
                        </div>
                        <div v-if="isSelected == 'Premium'" class="w-full relative flex justify-between my-[10px] items-center">
                            <h1 class="text-xs font-semibold text-gray-500">Premium:</h1>
                            <p class="text-sm font-bold text-gray-700"> <span class="text-[10px]">{{ forfaitSelected ? forfaitSelected : 0 }} FCFA</span></p>
                        </div>
                        <!-- Taxe -->
                        <div class="w-full relative flex justify-between py-[20px] border-b items-center">
                            <h1 class="text-xs font-semibold text-gray-500 uppercase">Taxe ({{ taxPercentage }}%): 
                                <i class="fa-solid fa-circle-info text-blue-500 cursor-pointer relative"
                                    @mouseenter="showTaxInfo = true"
                                    @mouseleave="showTaxInfo = false">
                                    <div v-if="showTaxInfo" class="absolute left-0 font-mono top-[20px] bg-gray-700 text-white p-[10px] text-xs rounded-[5px] w-[200px]">
                                      Les taxes sont de 1.8% pour Moov et MTN, et de 4% pour les cartes bancaires.
                                    </div>
                                </i>
                            </h1>
                            <p class="text-sm font-bold text-gray-700">{{ forfaitSelected ? totalTax : 0 }}<span class="text-[10px]">FCFA</span></p>
                        </div>
                        <!-- Total -->
                        <div class="w-full relative flex justify-between py-[20px] items-center">
                            <div>
                                <h1 class="text-sm font-bold text-gray-800 uppercase">Total:</h1>
                                <p class="text-[10px] font-bold text-gray-500">inclus les taxes</p>
                            </div>
                            <p class="text-xl font-bold text-gray-800">{{ totalWithTax ? totalWithTax : 0 }} <span class="text-[10px]">FCFA</span></p>
                        </div>
                        <button @click="payerCommande()" class="w-full mt-[20px] h-[45px] flex justify-center items-center p-[10px] rounded-[5px] bg-blue-950 text-white text-sm font-bold text-center">
                            <span v-if="!isLoading">Confirmer la commande</span>
                            <div v-if="isLoading" class="spinner"></div>
                        </button>
                    </div>
                </div>
                <div v-if="checking" class="w-full h-full right-0 bg-black/80 absolute bottom-0 z-10 flex justify-center items-center flex-col">
                    <div class="spinner"></div>
                    <h1 class="my-[10px] mt-[50px] text-sm font-bold text-gray-50">En attente de paiement !</h1>
                    <button @click="toggleCheck()" class="p-[10px] bg-blue-600 font-bold text-sm text-white rounded-[5px]">
                        Recommencer
                    </button>
                </div>
                <div v-if="isLoading1" class="w-full h-full right-0 bg-gray-600 absolute bottom-0 top-0 z-10 flex justify-center items-center flex-col">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
const isLoading1 = ref(false)
const checking = ref(false)
const numeroPaiement = ref('')
const allName = user.value.firstname + ' ' + user.value.lastname
const offreId = ref('')

const isPassword = ref(false)
const isInformation = ref(true)
const isAbonnement1 = ref(false)

function toggleInformation() {
    isInformation.value = true
    isPassword.value = false
    isAbonnement1.value = false
}
function togglePassword() {
    isInformation.value = false
    isPassword.value = true
    isAbonnement1.value = false
}
function toggleAbonnement() {
    isInformation.value = false
    isPassword.value = false
    isAbonnement1.value = true
}
// États pour stocker le jour, le mois et l'année
const day = ref('');
const month = ref('');
const year = ref('');

// Liste des jours de 1 à 31
const days = computed(() => Array.from({ length: 31 }, (_, i) => i + 1));

// Liste des mois
const months = ref([
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
]);

// Liste des années (de l'année actuelle à 100 ans en arrière)
const currentYear = new Date().getFullYear();
const years = computed(() => Array.from({ length: 100 }, (_, i) => currentYear - i));

// Optionnel: validation ou logique supplémentaire
const isDateValid = computed(() => {
  return day.value && month.value && year.value;
});

const errorMessage = ref('')
const successMessage = ref('')
const nom = ref('')
const prenoms = ref('')
const email = ref('')
const phone = ref('')
const Opassword = ref('')
const Npassword = ref('')
const CNpassword = ref('')

const submitForm = async() => {
    isLoading.value = true
    try {
        const response = await axios.put(`/api/client/${user.value._id}`, {
            firstname: prenoms.value,
            lastname: nom.value,
            email: email.value,
            phone: phone.value
        })
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = "Vos informations ont bien été modifiées"
            hideSuccessMessageAfterDelay()
            authStore.setUser(response.data.client);
        } else {
            errorMessage.value = "Erreur inattendue lors de la modification des informations"
            hideErrorMessageAfterDelay()
        }
        prenoms.value = ''
        nom.value = ''
        email.value = ''
        phone.value = ''
    } catch {
        errorMessage.value = "Erreur lors de la modification des informations"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const submitFormPassword = async() => {
    isLoading.value = true
    if (Npassword.value !== CNpassword.value) {
        errorMessage.value = "Les nouveaux mots de passe ne correspondent pas!"
        hideErrorMessageAfterDelay()
        isLoading.value = false

        return
    }
    try {
        const response = await axios.post(`/api/client/reset-password`, {
            email: user.value.email,
            oldPassword: Opassword.value,
            newPassword: Npassword.value
        })
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = "Votre mot de passe a été modifié avec succès"
            hideSuccessMessageAfterDelay()
        } else {
            errorMessage.value = "Erreur inattendue lors de la modification du mot de passe"
            hideErrorMessageAfterDelay()
        }
        Opassword.value = ''
        Npassword.value = ''
        CNpassword.value = ''
    } catch {
        errorMessage.value = "Erreur lors de la modification du mot de passe"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

onMounted(() => {
    nom.value = user.value.lastname
    prenoms.value = user.value.firstname
    email.value = user.value.email
    phone.value = user.value.phone
})

const isSelected = ref("")
const forfaitSelected = ref(null)

const selectPlan = (plan) => {
    isSelected.value = plan
}
const selectForfait = (forfait) => {
    forfaitSelected.value = forfait
}

// Sélection du mode de paiement
const selectedPayment = ref('moov');
const selectPayment = (method) => {
  selectedPayment.value = method;
};
// Calcul de la taxe et du total
const taxPercentage = computed(() => {
  return selectedPayment.value === 'mastercard' || selectedPayment.value === 'visa' ? 4 : 1.8;
});

const totalTax = computed(() => {
  return forfaitSelected ? ((forfaitSelected.value * taxPercentage.value) / 100) : 0;
});

const totalWithTax = computed(() => {
  return forfaitSelected.value + totalTax.value;
});

const payerCommande = async () => {
    isLoading.value = true
    try {
        const CommandeData = {
            nom: user.value.lastname,
            prenoms: user.value.firstname,
            email: user.value.email,
            numero: numeroPaiement.value,
            prix: Number(forfaitSelected.value),
            description: `Paiement de ${totalWithTax.value} FCFA pour la souscription au forfat ${selectPlan.value}`
        };
        const response = await axios.post('/paiement', CommandeData);

        window.location.href = response.data.redirectUrl;
    } catch (error) {
        errorMessage.value = 'Erreur lors du paiement';
        hideErrorMessageAfterDelay();
    } finally {
      isLoading.value = false
    }
};

const createVente = async () => {
  try {
    isLoading1.value = true
    const response = await axios.post('/api/client/subscribe', {
        userId: user.value._id, 
        subscriptionId: offreId.value
    })
    authStore.setUser(response.data.user);
    offreId.value = ''
    router.push('/dashboard')
    window.location.reload()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la création de la vente'
    hideErrorMessageAfterDelay()
  } finally {
    isLoading1.value = false 
  }
}


const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id');
const verification = ref({})
const verificationId = async () => {
  try {
    const response = await axios.get(`/verification/${id}`);
    verification.value = response.data
    if (verification.value.message === "Transaction approuvée"){
        createVente()
    } else if (verification.value.message === "Transaction en attente") {
        checking.value= true
    } else {
        checking.value = true
    }
  } catch (err) {
    errorMessage.value = true;
  }
};

function traiterPaiement(status) {
  if (status === 'pending') {
    checking.value = true
  } else if (status === 'approved') {
    verificationId()
  } else {
    checking.value = true
  }
}

const checkUrlAndExecute = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');
  const amount = urlParams.get('amount')

  if(amount == 300) {
    offreId.value = "66dbc2023c588a48f7c65fec"
  } else if (amount == 3000) {
    offreId.value = "66dbc23a3c588a48f7c65fee"
  } else if (amount == 500) {
    offreId.value = "66dc16f63c588a48f7c65ff6"
  } else if (amount == 5000) {
    offreId.value = "66dc171e3c588a48f7c65ff8"
  } else if (amount == 2000) {
    offreId.value = "66dc171e3c588a48f7c65ff8"
  } else {
    offreId.value = ""
  }
  if (status) {
    traiterPaiement(status);
  }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() =>{
    checkUrlAndExecute()
});

const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})
// Affichage de l'info-bulle pour la taxe
const showTaxInfo = ref(false);
const isAbonne = ref(false)
function toggleAbonnement1() {
    isAbonne.value = !isAbonne.value
}
</script>

<style scoped>
.birthday-fields {
  display: flex;
  gap: 1rem;
}
label {
  margin-right: 0.5rem;
}
</style>