<template>
    <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <div ref="firstMenu" :class="{'translate-x-[-100%]': !menuStore.isFirstMenuOpen, 'translate-x-0': menuStore.isFirstMenuOpen}" class="w-[250px] md:translate-x-0 fixed md:static duration-300 top-0 left-0 bottom-0 z-50 min-w-[250px] p-[20px] h-screen max-h-screen bg-[#151036]">
        <div class="w-full my-[10px] mb-[50px]">
            <h1 class="text-3xl text-white uppercase roboto-bold">Revixo</h1>
            <p class="text-sm text-gray-200 roboto-light uppercase">Tout pour réviser !!</p>
        </div>
        <div class="w-full overflow-y-auto h-[calc(100vh-280px)] parent">
            <div @click="goToDashboardPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/dashboard', 'text-white degrade3': $route.path == '/dashboard'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M3 13h8V3H3zm0 8h8v-6H3zm10 0h8V11h-8zm0-18v6h8V3z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Dashboard</h1>
            </div>
            <div @click="goToEpreuvesPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/epreuves' || isEpreuveRoute, 'text-white degrade3': $route.path == '/epreuves' || isEpreuveRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" fill-rule="evenodd" d="M39 13a3 3 0 0 0-3 3v2h6v-2a3 3 0 0 0-3-3m3 7h-6v16.5l3 4.5l3-4.5zM6 9v30a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3m14 6a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-1 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-9-3v3h3v-3zm-1-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1m6.707-10.293a1 1 0 0 0-1.414-1.414L13 17.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L13 20.414z" clip-rule="evenodd"/></g></svg>
                <h1 class="text-sm font-bold ml-[10px]">Epreuves / Corrigés</h1>
            </div>
            <div @click="goToExercicesPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/exercices' || isExercicesRoute, 'text-white degrade3': $route.path == '/exercices' || isExercicesRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"><path fill="currentColor" d="M10 6.5V2H5.5A1.5 1.5 0 0 0 4 3.5v5.55Q4.243 9 4.5 9h2c.818 0 1.544.393 2 1h5a.5.5 0 0 1 0 1H8.95l.008.042A2.5 2.5 0 0 1 10.5 12h3a.5.5 0 0 1 0 1h-2.55q.05.243.05.5v.5h2.5a.5.5 0 0 1 0 1H11v2.5q0 .257-.05.5h3.55a1.5 1.5 0 0 0 1.5-1.5V8h-4.5A1.5 1.5 0 0 1 10 6.5m1 0V2.25L15.75 7H11.5a.5.5 0 0 1-.5-.5M3 12v-.5A1.5 1.5 0 0 1 4.5 10h2A1.5 1.5 0 0 1 8 11.5v.5h.5a1.5 1.5 0 0 1 1.5 1.5V15H8v-.5a.5.5 0 0 0-1 0v.5H4v-.5a.5.5 0 0 0-1 0v.5H1v-1.5A1.5 1.5 0 0 1 2.5 12zm4-.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v.5h3zM8 16h2v1.5A1.5 1.5 0 0 1 8.5 19h-6A1.5 1.5 0 0 1 1 17.5V16h2v.5a.5.5 0 0 0 1 0V16h3v.5a.5.5 0 0 0 1 0z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Exercices</h1>
            </div>
            <div @click="goToBiblioPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/bibliotheques' || isBibliothequesRoute, 'text-white degrade3': $route.path == '/bibliotheques' || isBibliothequesRoute}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="m231.65 194.55l-33.19-157.8a16 16 0 0 0-19-12.39l-46.81 10.06a16.08 16.08 0 0 0-12.3 19l33.19 157.8A16 16 0 0 0 169.16 224a16.3 16.3 0 0 0 3.38-.36l46.81-10.06a16.09 16.09 0 0 0 12.3-19.03M136 50.15v-.09l46.8-10l3.33 15.87L139.33 66Zm6.62 31.47l46.82-10.05l3.34 15.9L146 97.53Zm6.64 31.57l46.82-10.06l13.3 63.24l-46.82 10.06ZM216 197.94l-46.8 10l-3.33-15.87l46.8-10.07l3.33 15.85zM104 32H56a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16M56 48h48v16H56Zm0 32h48v96H56Zm48 128H56v-16h48z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Bibliothèques</h1>
            </div>
            <div @click="goToMatieresPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative hidden justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/matieres', 'text-white degrade3': $route.path == '/matieres'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M22 4H2v4h20ZM6 7H4V5h2Zm14.6 7.8A2.09 2.09 0 0 0 19 14h-7.002a1 1 0 0 0-.998.998v6.004a1 1 0 0 0 .998.998H19a1.82 1.82 0 0 0 1.6-.8L23 18ZM11.998 12H19a4.08 4.08 0 0 1 3 1.39V10H2v4h7.184a2.99 2.99 0 0 1 2.814-2M4 13v-2h2v2Zm5 3H2v4h7Zm-5 3v-2h2v2Z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Matières</h1>
            </div>
            <div @click="goToActualitesPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/actualites', 'text-white degrade3': $route.path == '/actualites'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M4 7v12h15v2H4c-2 0-2-2-2-2V7zm17.3-4H7.7C6.76 3 6 3.7 6 4.55v10.9c0 .86.76 1.55 1.7 1.55h13.6c.94 0 1.7-.69 1.7-1.55V4.55C23 3.7 22.24 3 21.3 3M8 5h5v6H8zm13 10H8v-2h13zm0-4h-6V9h6zm0-4h-6V5h6z"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Actualités</h1>
            </div>
            <div @click="goToHelpsPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/helps', 'text-white degrade3': $route.path == '/helps'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M11.95 18q.525 0 .888-.363t.362-.887t-.362-.888t-.888-.362t-.887.363t-.363.887t.363.888t.887.362m.05 4q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m.1-14.3q.625 0 1.088.4t.462 1q0 .55-.337.975t-.763.8q-.575.5-1.012 1.1t-.438 1.35q0 .35.263.588t.612.237q.375 0 .638-.25t.337-.625q.1-.525.45-.937t.75-.788q.575-.55.988-1.2t.412-1.45q0-1.275-1.037-2.087T12.1 6q-.95 0-1.812.4T8.975 7.625q-.175.3-.112.638t.337.512q.35.2.725.125t.625-.425q.275-.375.688-.575t.862-.2"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Aide</h1>
            </div>
        </div>
        <div class="w-full absolute bottom-0 left-0 p-[20px] shadow-4xl bg-[#151036]">
            <div @click="goToSettingsPage()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/settings', 'text-white degrade3': $route.path == '/settings'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="m10.135 21l-.362-2.892q-.479-.145-1.035-.454q-.557-.31-.947-.664l-2.668 1.135l-1.865-3.25l2.306-1.739q-.045-.27-.073-.558q-.03-.288-.03-.559q0-.252.03-.53q.028-.278.073-.626L3.258 9.126l1.865-3.212L7.771 7.03q.448-.373.97-.673q.52-.3 1.013-.464L10.134 3h3.732l.361 2.912q.575.202 1.016.463t.909.654l2.725-1.115l1.865 3.211l-2.382 1.796q.082.31.092.569t.01.51q0 .233-.02.491q-.019.259-.088.626l2.344 1.758l-1.865 3.25l-2.681-1.154q-.467.393-.94.673t-.985.445L13.866 21zm1.838-6.5q1.046 0 1.773-.727T14.473 12t-.727-1.773t-1.773-.727q-1.052 0-1.776.727T9.473 12t.724 1.773t1.776.727"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Paramètres</h1>
            </div>
            <div @click="logout()" class="my-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px]" :class="{'text-gray-200': $route.path != '/correction', 'text-white degrade3': $route.path == '/correction'}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M16 13v-2H7V8l-5 4l5 4v-3z"/><path fill="currentColor" d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Se déconnecter</h1>
            </div>
        </div>
    </div>
    <div class="fixed top-0 left-0 w-full bg-white shadow-4xl z-40 md:hidden h-[70px] px-[20px] flex justify-between items-center">
        <div>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/dashboard'">Tableau de bord</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/epreuves' || isEpreuveRoute">Epreuves</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/corriges'">Corrigés</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/actualites'">Les Actualités</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/helps'">Besoin d'aide ?</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/settings'">Les paramètres</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/exercices' || isExercicesRoute">Exercices</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/bibliotheques' || isBibliothequesRoute">Bibliothèque</h1>
            <h1 class="text-sm font-bold text-gray-700" v-if="$route.path =='/bibliotheques/tous-les-livres'">Tous les livres</h1>
        </div>
        <div class="text-3xl text-gray-800 cursor-pointer flex" @click="handleMenuButtonClick($event)">
            <span v-if="!menuStore.isFirstMenuOpen"><i class="fa-solid fa-bars"></i></span>
            <span v-else><i class="fa-solid fa-times"></i></span>
        </div>
    </div>
    <div v-if="!isAbonnement" class="bg-black/80 z-[100000] w-full h-full top-0 left-0 fixed flex justify-center items-center">
        <div class="md:h-[80%] md:w-[80%] p-[20px] md:p-[50px] pt-[50px] w-[100%] h-[100%] bg-white flex flex-col items-center relative shadow-4xl overflow-y-auto parent">
            
            <h1 class="text-2xl font-bold text-blue-950 text-center">Salut {{ nom }}</h1>
            <p class="my-[10px] text-center max-w-[600px] px-[20px] text-sm font-semibold text-gray-600">
                Votre compte n'est pas encore actif parce que vous n'avez pas encore souscrit à un abonnement. Veuillez choisir ci dessous le forfait qui vous plait !
            </p>
            <div class="flex w-full relative mt-[50px] md:justify-start justify-center flex-wrap items-center">
                <div class="md:w-[200px] md:mb-0 mb-[20px] w-full flex items-center justify-center">
                    <h1 class="text-2xl font-bold text-gray-800 w-full text-center">
                        Choisissez un plan
                    </h1>
                </div>
                <div @click="selectPlan('Standar')" class="md:w-[300px] w-[340px] cursor-pointer h-auto relative min-w-[300px] my-[10px] mx-0 md:mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': isSelected == 'Standar', 'shadow-4xl': isSelected != 'Standar'}">
                    <div class="w-full h-[150px] rounded-[20px] flex flex-col justify-center items-center relative overflow-hidden">
                        <div class="w-[120px] h-[120px] relative flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/standar.jpeg" alt="" >
                        </div>
                        <h1 class="text-sm font-bold text-gray-800 mt-[10px]">Standard</h1>
                    </div>
                    <div class="w-full p-[20px]">
                        <ul>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Epreuves et Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Exercices Pratiques Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Recommandations périodiques</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Bibliothèque (Promo)</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Actualités et informations (Promo)</p>
                            </li>
                        </ul>
                    </div>
                    <div v-if="isSelected == 'Standar'" class="w-[30px] h-[30px] rounded-[50%] bg-blue-200 border-[5px] border-blue-600 absolute z-40 right-[20px] top-[20px]"></div>
                </div>
                <div @click="selectPlan('Premium')" class="md:w-[300px] w-[340px] cursor-pointer h-auto relative min-w-[300px] my-[10px] mx-0 md:mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': isSelected == 'Premium', 'shadow-4xl': isSelected != 'Premium'}">
                    <div class="w-full h-[150px] rounded-[20px] flex flex-col justify-center items-center relative overflow-hidden">
                        <div class="w-[120px] h-[120px] relative flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/premium.jpeg" alt="" >
                        </div>
                        <h1 class="text-sm font-bold text-gray-800 mt-[10px]">Premium</h1>
                    </div>
                    <div class="w-full p-[20px]">
                        <ul>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Epreuves et Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Exercices Pratiques Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Recommandations périodiques</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Bibliothèque</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Actualités et informations</p>
                            </li>
                        </ul>
                    </div>
                    <div v-if="isSelected == 'Premium'" class="w-[30px] h-[30px] rounded-[50%] bg-blue-200 border-[5px] border-blue-600 absolute z-40 right-[20px] top-[20px]"></div>
                </div>
            </div>
            <div v-if="isSelected == 'Premium'" class="flex w-full relative mt-[50px] md:justify-start justify-center flex-wrap items-center">
                <div class="md:w-[200px] md:mb-0 mb-[20px] w-full flex items-center justify-center">
                    <h1 class="text-2xl font-bold text-gray-800 w-full text-center">
                        Choisissez un forfait
                    </h1>
                </div>
                <div @click="selectForfait(5000)" class="w-[120px] cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 5000, 'shadow-4xl': forfaitSelected != 5000}">
                    
                    <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            5000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                        </h1>
                    </div>
                    <div v-if="forfaitSelected == 5000" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                </div>
                <div disabled style="filter: blur(10px); pointer-events: none;" class="w-[120px] bl cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 500, 'shadow-4xl': forfaitSelected != 500}">
                    <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            500 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                        </h1>
                    </div>
                    <div v-if="forfaitSelected == 500" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                </div>
                <div @click="selectForfait(500)" class="w-[120px] hidden cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 500, 'shadow-4xl': forfaitSelected != 500}">
                    <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            500 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                        </h1>
                    </div>
                    <div v-if="forfaitSelected == 500" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                </div>
            </div>
            <div v-if="isSelected == 'Standar'" class="flex w-full relative mt-[50px] md:justify-start justify-center flex-wrap items-center">
                <div class="md:w-[200px] md:mb-0 mb-[20px] w-full flex items-center justify-center">
                    <h1 class="text-2xl font-bold text-gray-800 w-full text-center">
                        Choisissez un forfait
                    </h1>
                </div>
                <div @click="selectForfait(3000)" class="w-[120px] cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 3000, 'shadow-4xl': forfaitSelected != 3000}">
                    
                    <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            3000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                        </h1>
                    </div>
                    <div v-if="forfaitSelected == 3000" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                </div>
                <div disabled style="filter: blur(10px); pointer-events: none;" class="w-[120px] bl cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 500, 'shadow-4xl': forfaitSelected != 500}">
                    <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            300 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                        </h1>
                    </div>
                    <div v-if="forfaitSelected == 300" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                </div>
                <div @click="selectForfait(300)" class="w-[120px] hidden cursor-pointer h-auto relative my-[10px] mx-[20px] rounded-[25px] max-w-[100%]" :class="{'shadow-10xl': forfaitSelected == 300, 'shadow-4xl': forfaitSelected != 300}">
                    <div class="p-[20px] flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            300 <span class="text-[10px] text-gray-500 font-bold">FCFA / semaine</span>
                        </h1>
                    </div>
                    <div v-if="forfaitSelected == 300" class="w-[15px] h-[15px] rounded-[50%] bg-blue-200 border-[2px] border-blue-600 absolute z-40 right-[10px] top-[10px]"></div>
                </div>
            </div>
            <div class="w-full bg-white rounded-[10px] mt-[20px] py-[20px]">
                <h1 class="text-xl font-bold text-gray-800 pb-[20px] border-b">Paiement</h1>
                <div class="w-full flex py-[10px]">
                    <div @click="selectPayment('moov')" :class="{'border-blue-500 border-2': selectedPayment === 'moov'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl rounded-[5px] flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/moov.png" alt="Moov">
                    </div>
                    <div @click="selectPayment('mtn')" :class="{'border-blue-500 border-2': selectedPayment === 'mtn'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl mx-[20px] rounded-[5px] flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/mtn.png" alt="MTN">
                    </div>
                    <div @click="selectPayment('mastercard')" :class="{'border-blue-500 border-2': selectedPayment === 'mastercard'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl rounded-[5px] flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/master.png" alt="Mastercard">
                    </div>
                </div>
            
                <div v-if="selectedPayment === 'mastercard' || selectedPayment === 'visa'" class="mt-[20px]">
                    <label class="block mb-2 text-sm font-medium text-gray-900">Numéro de Carte</label>
                    <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="1234 5678 9123 4567">
                    <div class="w-full relative flex justify-between items-center">
                        <div class="w-[70%] relative">
                            <label class="block mt-[10px] mb-2 text-sm font-medium text-gray-900">Expiration</label>
                            <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="MM/AA">
                        </div>
                        <div class="w-[28%] relative">
                            <label class="block mt-[10px] mb-2 text-sm font-medium text-gray-900">CVC</label>
                            <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="123">
                        </div>
                    </div>
                </div>
            
                <div v-if="selectedPayment === 'moov' || selectedPayment === 'mtn'" class="mt-[20px]">
                    <label class="block mb-2 text-sm font-medium text-gray-900">Numéro de Téléphone</label>
                    <input type="text" v-model="numeroPaiement" class="w-full p-2 border rounded-[5px] outline-none" placeholder="Entrez votre numéro">
                </div>
            </div>
            <div class="w-full h-auto bg-white py-[20px]">
                <h1 class="text-gray-800 text-xl mb-[20px] font-bold relative">Résumé d'achat</h1>
                <div class="w-full relative">
                    <div v-if="isSelected == 'Standar'" class="w-full relative flex justify-between my-[10px] items-center">
                        <h1 class="text-xs font-semibold text-gray-500">Standard:</h1>
                        <p class="text-sm font-bold text-gray-700"> <span class="text-[10px]">{{ forfaitSelected ? forfaitSelected : 0 }} FCFA</span></p>
                    </div>
                    <div v-if="isSelected == 'Premium'" class="w-full relative flex justify-between my-[10px] items-center">
                        <h1 class="text-xs font-semibold text-gray-500">Premium:</h1>
                        <p class="text-sm font-bold text-gray-700"> <span class="text-[10px]">{{ forfaitSelected ? forfaitSelected : 0 }} FCFA</span></p>
                    </div>
                    <!-- Taxe -->
                    <div class="w-full relative flex justify-between py-[20px] border-b items-center">
                        <h1 class="text-xs font-semibold text-gray-500 uppercase">Taxe ({{ taxPercentage }}%): 
                            <i class="fa-solid fa-circle-info text-blue-500 cursor-pointer relative"
                                @mouseenter="showTaxInfo = true"
                                @mouseleave="showTaxInfo = false">
                                <div v-if="showTaxInfo" class="absolute left-0 font-mono top-[20px] bg-gray-700 text-white p-[10px] text-xs rounded-[5px] w-[200px]">
                                  Les taxes sont de 1.8% pour Moov et MTN, et de 4% pour les cartes bancaires.
                                </div>
                            </i>
                        </h1>
                        <p class="text-sm font-bold text-gray-700">{{ forfaitSelected ? totalTax : 0 }}<span class="text-[10px]">FCFA</span></p>
                    </div>
                    <!-- Total -->
                    <div class="w-full relative flex justify-between py-[20px] items-center">
                        <div>
                            <h1 class="text-sm font-bold text-gray-800 uppercase">Total:</h1>
                            <p class="text-[10px] font-bold text-gray-500">inclus les taxes</p>
                        </div>
                        <p class="text-xl font-bold text-gray-800">{{ totalWithTax ? totalWithTax : 0 }} <span class="text-[10px]">FCFA</span></p>
                    </div>
                    <button @click="payerCommande()" class="w-full mt-[20px] h-[45px] flex justify-center items-center p-[10px] rounded-[5px] bg-blue-950 text-white text-sm font-bold text-center">
                        <span v-if="!isLoading">Confirmer la commande</span>
                        <div v-if="isLoading" class="spinner"></div>
                    </button>
                </div>
            </div>
            <div v-if="checking" class="w-full h-full right-0 bg-black/80 absolute bottom-0 z-10 flex justify-center items-center flex-col">
                <div class="spinner"></div>
                <h1 class="my-[10px] mt-[50px] text-sm font-bold text-gray-50">En attente de paiement !</h1>
                <button @click="toggleCheck()" class="p-[10px] bg-blue-600 font-bold text-sm text-white rounded-[5px]">
                    Recommencer
                </button>
            </div>
            <div v-if="isLoading1" class="w-full h-full right-0 bg-gray-600 absolute bottom-0 top-0 z-10 flex justify-center items-center flex-col">
                <div class="spinner"></div>
            </div>
            <div @click="logout()" class="mb-[10px] p-[10px] cursor-pointer hoverr w-full relative flex justify-start items-center rounded-[5px] text-white degrade3">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M16 13v-2H7V8l-5 4l5 4v-3z"/><path fill="currentColor" d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2"/></svg>
                <h1 class="text-sm font-bold ml-[10px]">Se déconnecter</h1>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'
import { useMenuStore } from '@/store/menu';
import dayjs from 'dayjs';

const menuStore = useMenuStore();
const firstMenu = ref(null)

const toggleFirstMenu = () => {
  menuStore.toggleFirstMenu();
};

// Gérer le clic sur le bouton du menu
const handleMenuButtonClick = (event) => {
  event.stopPropagation(); // Empêche la propagation du clic
  toggleFirstMenu();
};

const handleClickOutside = (event) => {
  if (firstMenu.value && !firstMenu.value.contains(event.target)) {
    menuStore.closeMenus();
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const handleLogout = () => {
  authStore.logout();
  
  router.push({ name: 'Login' });
};

const route = useRoute()
const router = useRouter()
const isLoading = ref(false)
const isLoading1 = ref(false)
const checking = ref(false)
const numeroPaiement = ref('')
const nom = user.value.firstname + ' ' + user.value.lastname
const errorMessage = ref('')
const successMessage = ref('')
const offreId = ref('')

const isCorrigeRoute = computed(() => route.path.startsWith('/corriges'))
const isEpreuveRoute = computed(() => route.path.startsWith('/epreuves'))
const isExercicesRoute = computed(() => route.path.startsWith('/exercices'))
const isBibliothequesRoute = computed(() => route.path.startsWith('/bibliotheques'))
const logout = async () => {
  try {
    await axios.post('/api/client/logout', {}, {
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    });
    authStore.logout();
    router.push('/login');
  } catch (err) {
    if (err.response && err.response.status === 401) {
      errorMessage.value = "Session expirée. Vous êtes déconnecté automatiquement.";
      hideErrorMessageAfterDelay()
    } else {
      errorMessage.value = "Erreur lors de la déconnexion.";
      hideErrorMessageAfterDelay()
    }
  } finally {
    isLoading.value = false;
  }
};
function toggleCheck() {
    checking.value = false
}
function goToDashboardPage(){
    router.push("/dashboard")
}
function goToEpreuvesPage() {
    router.push("/epreuves")
}
function goToCorrigesPage() {
    router.push("/corriges")
}
function goToExercicesPage() {
    router.push("/exercices")
}
function goToMatieresPage() {
    router.push("/matieres")
}
function goToBiblioPage() {
    router.push("/bibliotheques")
}
function goToSettingsPage() {
    router.push("/settings")
}
function goToHelpsPage() {
    router.push("/helps")
}
function goToActualitesPage() {
    router.push("/actualites")
}

const isOpen = ref(false)

function toggleMenu() {
    isOpen.value = !isOpen.value
}

const isSelected = ref("")
const forfaitSelected = ref(null)

const selectPlan = (plan) => {
    isSelected.value = plan
}
const selectForfait = (forfait) => {
    forfaitSelected.value = forfait
}

const subscriptionEndDate = user.value.subscriptionEndDate; // End date
    
const end = dayjs(subscriptionEndDate);
const now = dayjs();
const difference = end.diff(now); // Difference in milliseconds

// Sélection du mode de paiement
const selectedPayment = ref('moov');
const selectPayment = (method) => {
  selectedPayment.value = method;
};
// Calcul de la taxe et du total
const taxPercentage = computed(() => {
  return selectedPayment.value === 'mastercard' || selectedPayment.value === 'visa' ? 4 : 1.8;
});

const totalTax = computed(() => {
  return forfaitSelected ? ((forfaitSelected.value * taxPercentage.value) / 100) : 0;
});

const totalWithTax = computed(() => {
  return forfaitSelected.value + totalTax.value;
});

const payerCommande = async () => {
    isLoading.value = true
    try {
        const CommandeData = {
            nom: user.value.lastname,
            prenoms: user.value.firstname,
            email: user.value.email,
            numero: numeroPaiement.value,
            prix: Number(forfaitSelected.value),
            description: `Paiement de ${totalWithTax.value} FCFA pour la souscription au forfat ${selectPlan.value}`
        };
        const response = await axios.post('/paiement', CommandeData);

        window.location.href = response.data.redirectUrl;
    } catch (error) {
        errorMessage.value = 'Erreur lors du paiement';
        hideErrorMessageAfterDelay();
    } finally {
      isLoading.value = false
    }
};

const createVente = async () => {
  try {
    isLoading1.value = true
    const response = await axios.post('/api/client/subscribe', {
        userId: user.value._id, 
        subscriptionId: offreId.value
    })
    authStore.setUser(response.data.user);
    offreId.value = ''
    router.push('/dashboard')
    window.location.reload()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la création de la vente'
    hideErrorMessageAfterDelay()
  } finally {
    isLoading1.value = false 
  }
}


const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id');
const verification = ref({})
const verificationId = async () => {
  try {
    const response = await axios.get(`/verification/${id}`);
    verification.value = response.data
    if (verification.value.message === "Transaction approuvée"){
        createVente()
    } else if (verification.value.message === "Transaction en attente") {
        checking.value= true
    } else {
        checking.value = true
    }
  } catch (err) {
    errorMessage.value = true;
  }
};

function traiterPaiement(status) {
  if (status === 'pending') {
    checking.value = true
  } else if (status === 'approved') {
    verificationId()
  } else {
    checking.value = true
  }
}

const checkUrlAndExecute = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');
  const amount = urlParams.get('amount')

  if(amount == 300) {
    offreId.value = "66dbc2023c588a48f7c65fec"
  } else if (amount == 3000) {
    offreId.value = "66dbc23a3c588a48f7c65fee"
  } else if (amount == 500) {
    offreId.value = "66dc16f63c588a48f7c65ff6"
  } else if (amount == 5000) {
    offreId.value = "66dc171e3c588a48f7c65ff8"
  } else {
    offreId.value = ""
  }
  if (status) {
    traiterPaiement(status);
  }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() =>{
    checkUrlAndExecute()
});

const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})
// Affichage de l'info-bulle pour la taxe
const showTaxInfo = ref(false);
const isAbonnement = ref(false)

onMounted(() => {
    if(user.value.isAbonne == false) {
        isAbonnement.value = false
    } else {
        isAbonnement.value = true
    }
})
onMounted(() => {
  if (difference <= 0) {
    isAbonnement.value = false
  }
})
</script>

<style>
</style>