<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <div class="flex flex-col w-[calc(100%-130px)]">
                            <h1 class="text-sm clamp font-bold text-gray-800">{{ epreuve.matiere }}</h1>
                            <p class="text-[10px] clamp font-semibold text-gray-500">
                                {{ epreuve.correction ? epreuve.correction.titre : 'Pas de correction disponible' }}
                            </p>
                        </div>
                        <div class="flex items-center">
                            <div @click="gotoCorrectionPage(epreuve._id)" class="p-[10px] cursor-pointer rounded-[5px] flex justify-center text-sm fon-bold items-center bg-gray-100 shadow-7xl text-gray-600">
                                <i class="fa-solid fa-eye text-sm font-bold mr-[5px]"></i>
                                Voir l'épreuve
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full parent relative flex justify-center items-center flex-col">
                <div class="w-full flex justify-center items-center relative mt-[10px]">
                    <div id="pdf-container" @contextmenu.prevent></div>
                </div>
            </div>
            <div class="w-full my-[50px] relative flex flex-col justify-center items-center">
                <div class="w-full relative my-[10px]">
                    <h1 class="w-full relative py-[10px] border-b border-gray-200 flex  justify-between items-center">
                        <span class="text-sm font-bold text-gray-500">{{ epreuve.correction ? epreuve.correction.commentaire.length : 0 }} commentaires</span>
                        <span class="text-xs font-bold text-gray-500 flex items-center">
                            <button @click="likeCorrection" class="flex mr-[15px] items-center">
                                <i v-if="liked" class="fa-solid fa-heart text-red-600 mr-[5px] text-xl"></i>
                                <i class="fa-solid fa-heart-crack"></i>
                                {{ likesCount }}
                            </button>
                            <strong class="text-gray-400">
                                <i class="fa-solid fa-eye"></i>
                                
                            </strong>
                        </span>
                    </h1>
                </div>
                <form @submit.prevent="submitComment()" class="w-full relative mt-[20px]">
                    <div class="my-[10px] w-full relative flex items-start justify-center">
                        <div class="w-[30px] md:w-[50px] md:mr-[20px] mr-[15px] h-[30px] md:h-[50px] min-w-[30px] md:min-w-[50px] min-h-[30px] md:min-h-[50px] flex justify-center items-center relative overflow-hidden rounded-[50%]">
                            <img src="../assets/images/avatar.jpeg" alt="" class="w-full h-full absolute object-cover">
                        </div>
                        <div class="w-full flex flex-col justify-center items-center relative">
                            <div class="w-full relative">
                                <input type="text" readonly required placeholder="Votre commentaire est anonyme" class="w-full p-[10px] border border-gray-200 outline-none text-sm rounded-[5px] bg-transparent">
                            </div>
                            <div class="w-full my-[15px] relative">
                                <textarea type="text" v-model="content" required placeholder="Entrez votre commentaire" class="w-full p-[10px] h-[120px] resize-none border border-gray-200 outline-none text-sm rounded-[5px] bg-transparent"></textarea>
                            </div>
                            <div class="w-full relative">
                                <button :disabled="isLoading" type="submit" class="p-[10px] w-[100px] h-[45px] flex justify-center items-center text-center bg-gray-800 font-bold text-white text-sm rounded-[5px]">
                                    <span v-if="!isLoading">Envoyer</span>
                                    <div v-if="isLoading" class="spinner"></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div v-if="epreuve.correction && epreuve.correction.commentaire && epreuve.correction.commentaire.length > 0" class="flex flex-col w-full relative items-center justify-center">
                    <div v-for="(commentaire, index) in epreuve.correction.commentaire" :key="index" class=" w-full text-sm text-gray-700">
                        <div class="w-full my-[10px] rounded-[5px] p-[10px] bg-gray-200">
                            <h1 v-if="commentaire && commentaire.content">
                                {{ commentaire.content }}
                            </h1>
                            <p class="mt-[5px] text-xs font-bold text-gray-500">
                                Anonyme
                            </p>
                        </div>
                        <div v-if="commentaire.reponse && commentaire.reponse.length > 0">
                            <div v-for="(reponse, idx) in commentaire.reponse" :key="idx" class="w-[calc(100%-25px)] ml-[25px] my-[10px] bg-green-200 rounded-[5px] p-[10px] text-sm text-gray-700">
                                <h1>{{ reponse.content }}</h1>
                                <p class="mt-[5px] text-xs font-bold text-green-600">
                                    Administrateur
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full my-[10px] text-sm text-gray-700">
                    Il n'y a pas de commentaires pour cette correction.
                </div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { useAuthStore } from '@/store/auth';

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const router = useRouter()
const epreuveId = router.currentRoute.value.params.id;
const epreuve = ref({})
const errorMessage = ref('')
const successMessage = ref('')
const epreuveContent = ref('')
const content = ref('')
const nom = user.value.firstname + ' ' + user.value.lastname
const userId = user.value._id
const isLoading = ref(false)
const liked = ref(false);
const likesCount = ref(0); // Remplacer par la valeur initiale

const likeCorrection = async () => {
  if (liked.value) {
    return alert('Vous avez déjà liké cette correction');
  }

  try {
    const response = await axios.put(`/api/epreuves/${epreuveId}/like`, { userId });

    if (response.status === 200) {
      const data = response.data;
      likesCount.value = data.correction.likes;
      liked.value = true;
    } else {
      alert(response.data.message);
    }
  } catch (error) {
    console.error('Erreur:', error);
  }
};


function gotoCorrectionPage(id) {
    router.push(`/epreuves/${id}`)
}

const submitComment = async() => {
    isLoading.value = true
    try {
        const response = await axios.post(`/api/epreuves/${epreuveId}/commentaire`, {
            nom: nom,
            content: content.value 
        })
        if ( response.status == 200 || response.status == 201 ) {
            successMessage.value = "Commentaire envoyé avec succès"
            hideSuccessMessageAfterDelay()
            fetchEpreuveDetail()
        } else {
            errorMessage.value = "Erreur lors de l'envoie du commentaire"
            hideErrorMessageAfterDelay()
        }
        content.value = ""
    } catch (error) {
        errorMessage.value = "Erreur lors de l'envoie du commentaire"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const fetchEpreuveDetail = async() => {
    try {
        const response = await axios.get(`/api/epreuves/${epreuveId}`)
        epreuve.value = response.data
        likesCount.value = epreuve.value.correction ? epreuve.value.correction.likes : 0
        displayPDF();
    } catch (error) {
        errorMessage.value = 'Erreur lors du chargement de l\'épreuve'
        hideErrorMessageAfterDelay()
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchEpreuveDetail()
})

const displayPDF = async () => {
    const container = document.getElementById('pdf-container');
    container.innerHTML = ''; // Vide le conteneur avant de charger les pages

    const correctedPdfUrl = `https://api.revixo-learn.online/${epreuve.value.correction.content.replace(/\\/g, '/')}`;

    try {
        const pdf = await pdfjsLib.getDocument(correctedPdfUrl).promise;
        const containerWidth = container.clientWidth;
        const pixelRatio = window.devicePixelRatio || 1; // Récupère la densité de pixels de l'écran

        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);

            // Calculer l'échelle pour s'adapter à la largeur du conteneur
            const viewport = page.getViewport({ scale: 1 });
            const scale = containerWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Ajuster la résolution pour une meilleure netteté
            canvas.height = scaledViewport.height * pixelRatio;
            canvas.width = scaledViewport.width * pixelRatio;
            canvas.style.height = `${scaledViewport.height}px`;  // Taille CSS
            canvas.style.width = `${scaledViewport.width}px`;    // Taille CSS

            // Désactive le clic droit et la sélection de texte
            canvas.style.pointerEvents = 'none';
            canvas.style.userSelect = 'none';

            // Rendre la page sur le canvas en tenant compte du ratio de pixels
            context.scale(pixelRatio, pixelRatio);
            await page.render({ canvasContext: context, viewport: scaledViewport }).promise;

            container.appendChild(canvas); // Ajouter le canvas après le rendu complet de la page
        }
    } catch (error) {
        errorMessage.value = 'Erreur lors de l\'affichage du PDF';
        hideErrorMessageAfterDelay();
    }
};


</script>

<style scoped>
#pdf-container {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
canvas {
  pointer-events: none;
  width: 100%;
}
</style>  