<template>
    <div ref="secondMenu" id="parent" :class="{'translate-x-[100%]': !menuStore.isSecondMenuOpen, 'translate-x-0 top-[70px] h-[calc(100vh-70px)]': menuStore.isSecondMenuOpen}" class="w-[280px] border-l lg:translate-x-0 fixed lg:static lg:h-screen overflow-y-auto duration-300 top-0 right-0 bottom-0 z-50 min-w-[280px] p-[20px] h-screen max-h-screen bg-white">
        <div class="w-full rounded-[10px] h-[250px] bg-blue-950 p-[10px] flex relative justify-center items-center flex-col">
            <div class="w-[60px] h-[60px] rounded-[50%] relative flex justify-center items-center overflow-hidden">
                <img src="../assets/images/icone.png" alt="" class="w-full h-full absolute object-contain">
            </div>
            <h1 class="text-center text-white font-bold text-sm flex justify-center items-center mt-[10px] ">{{ nom }} <p class=" mt-[-15px]"><i class="fa-solid fa-circle-check text-blue-500"></i></p></h1>
            <p v-if="user" class="text-[12px] text-gray-200 text-center">
                {{ user.email }}
            </p>
            <div class="w-full relative flex justify-between items-center mt-[20px]">
                <div class="p-[10px] bg-blue-50/40 w-full rounded-[5px] flex flex-col justify-center items-center">
                    <h1 class="text-sm font-bold text-white">{{ Infos.length }}</h1>
                    <p class="text-gray-200 mt-[10px] text-[10px]">Actualités</p>
                </div>
                <div class="p-[10px] bg-blue-50/40 w-full ml-[10px] rounded-[5px] flex flex-col justify-center items-center">
                    <h1 class="text-sm font-bold text-white">{{ exercices.length }}</h1>
                    <p class="text-gray-200 mt-[10px] text-[10px]">Exercices</p>
                </div>
                <div class="p-[10px] bg-blue-50/40 rounded-[5px] ml-[10px] w-full flex flex-col justify-center items-center">
                    <h1 class="text-sm font-bold text-white">{{ epreuves.length }}</h1>
                    <p class="text-gray-200 mt-[10px] text-[10px]">Corrigés</p>
                </div>
            </div>
        </div>
        <div class="w-full rounded-[10px] bg-gray-100 mt-[20px]">
            <div class="calendar">
                <div class="calendar-header">
                    <span class="text-sm font-bold text-gray-800">{{ currentMonth }} {{ currentYear }}</span>
                    <div class="flex justify-center items-center">
                        <button @click="prevMonth" class="font-bold w-[25px] h-[25px] rounded-[5px] bg-gray-800 text-white flex justify-center items-center text-center text-xs mx-[15px]"><i class="fa-solid fa-chevron-left"></i></button>
                        <button @click="nextMonth" class="font-bold w-[25px] h-[25px] rounded-[5px] bg-gray-800 text-white flex justify-center items-center text-center text-xs"><i class="fa-solid fa-chevron-right"></i></button>
                    </div>
                </div>
                <div class="calendar-body">
                    <div class="calendar-grid">
                        <div class="day" v-for="day in daysOfWeek" :key="day">{{ day }}</div>
                        <div class="day" v-for="day in emptyDays" :key="'empty' + day" ></div>
                        <div class="day" v-for="day in daysInMonth" :key="day" :class="{ 'current-day': isCurrentDay(day) }">
                          {{ day }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div @click="handleMenuButtonClick($event)" class="fixed top-[17.5px] md:hidden cursor-pointer right-[70px] w-[35px] z-50 h-[35px] rounded-[50%] flex justify-center items-center border border-gray-200 overflow-hidden shadow-6xl">
        <img src="../assets/images/icone.png" alt="" class="w-full h-full absolute object-contain">
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'
import { useMenuStore } from '@/store/menu';

const menuStore = useMenuStore();
const secondMenu = ref(null)

const toggleSecondMenu = () => {
  menuStore.toggleSecondMenu();
};

// Gérer le clic sur le bouton du menu
const handleMenuButtonClick = (event) => {
  event.stopPropagation(); // Empêche la propagation du clic
  toggleSecondMenu();
};

const handleClickOutside = (event) => {
  if (secondMenu.value && !secondMenu.value.contains(event.target)) {
    menuStore.closeMenus();
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const authStore = useAuthStore();
const user = computed(() => authStore.user);


const nom = user.value.firstname + ' ' + user.value.lastname
const isInfo = ref(false)
const errorMessage = ref('')
const successMessage = ref('')

function toggleInfo() {
    isInfo.value = !isInfo.value
}

const today = new Date()
const currentDate = ref(today)
const currentYear = ref(today.getFullYear())
const currentMonth = ref(today.toLocaleString('default', { month: 'long' }))

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const daysInMonth = ref([])
const emptyDays = ref([])

const updateCalendar = () => {
  const firstDayOfMonth = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth(), 1)
  const lastDayOfMonth = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth() + 1, 0)

  const days = []
  for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
    days.push(i)
  }
  daysInMonth.value = days

  const empty = []
  for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
    empty.push(i)
  }
  emptyDays.value = empty
}

const isCurrentDay = (day) => {
  return (
    day === today.getDate() &&
    currentDate.value.getMonth() === today.getMonth() &&
    currentDate.value.getFullYear() === today.getFullYear()
  )
}

const prevMonth = () => {
  currentDate.value.setMonth(currentDate.value.getMonth() - 1)
  currentYear.value = currentDate.value.getFullYear()
  currentMonth.value = currentDate.value.toLocaleString('default', { month: 'long' })
  updateCalendar()
}

const nextMonth = () => {
  currentDate.value.setMonth(currentDate.value.getMonth() + 1)
  currentYear.value = currentDate.value.getFullYear()
  currentMonth.value = currentDate.value.toLocaleString('default', { month: 'long' })
  updateCalendar()
}

const epreuves = ref([])
const isLoading = ref(false)

const fetchEpreuves = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/epreuves')
        epreuves.value = response.data
    } catch {
        errorMessage.value = "Erreur lors du chargement des épreuves"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const exercices = ref([])
const fetchExercice = async() => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/exercices')
        exercices.value = response.data
    } catch {
        errorMessage.value = "Erreur lors du chargement des exercices"
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})

const Infos = ref([])
const fetchInfo = async () => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/news')
        Infos.value = response.data
    } catch (error) {
        errorMessage.value = 'Erreur lors du chargement des informations'
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchInfo()
    fetchEpreuves()
    fetchExercice()
})

updateCalendar()
</script>

<style>
.calendar {
  width: 100%;
  padding: 10px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-body {
  display: grid;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day {
  text-align: center;
  font-size: 10px;
    width: 20px;
    height: 20px;
}

.current-day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: black;
    color: white;
}
</style>