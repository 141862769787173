<template>
  <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
      <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
          {{ errorMessage }}
      </div>
      <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
          {{ successMessage }}
      </div>
  </div>
    <section class="w-full degrade1 h-screen overflow-hidden z-0 relative flex justify-center md:p-[20px] p-[10px] items-center">
        <div class="container degrade2 max-w-[924px] h-full md:max-h-[550px] relative rounded-[10px] shadow-4xl flex flex-col md:flex-row justify-center items-center">
            <div class="md:w-6/12 relative z-10 w-full h-full md:flex hidden">
                <img src="../assets/images/Revision2.jpg" alt="">
            </div>
            <div class="md:w-6/12 w-full lg:h-full h-auto bg-gray-100 overflow-y-auto parent rounded-l-[10px] md:rounded-l-none rounded-r-[10px] md:p-[50px] p-[25px] flex flex-col justify-start items-center">
                <h1 class="font-bold text-center text-gray-800">
                    Salut 🤗, Bienvenue sur notre plateforme !
                </h1>
                <p class="text-xs text-center my-[15px] font-semibold text-gray-500">
                    Vous avez déjà un compte ? <button @click="goToLoginPage()" class="text-blue-500 hover:underline"> Connectez vous </button>
                </p>
                <form action="" @submit.prevent="handleSubmit()" class="my-[10px] w-full relative">
                    <div class="w-full relative md:flex justify-between items-center">
                        <div class="md:w-[48%] w-full relative mt-[15px]">
                            <input type="text" required v-model="prenoms" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Prénoms">
                        </div>
                        <div class="md:w-[48%] w-full relative mt-[15px]">
                            <input type="text" required v-model="nom" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Nom">
                        </div>
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="text" required v-model="email" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Email">
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="password" required v-model="password" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Mot de passe">
                    </div>
                    <div class="w-full relative my-[15px]">
                        <input type="password" required v-model="Cpassword" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500" placeholder="Confirmez le Mot de passe">
                    </div>
                    <div class="w-full relative md:flex justify-between items-center">
                      <!-- Select for Filière -->
                      <div class="md:w-[48%] w-full relative md:mt-0 mt-[15px]">
                        <select required v-model="selectedFiliere" @change="filterAnnees" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500">
                          <option value="" disabled selected hidden>Filière</option>
                          <option v-for="filiere in filieres" :key="filiere.nom" :value="filiere.nom">
                            {{ filiere.nom }}
                          </option>
                        </select>
                      </div>
                    
                      <!-- Select for Année Scolaire -->
                      <div class="md:w-[48%] w-full relative md:mt-0 mt-[15px]">
                        <select required v-model="selectedAnnee" class="p-[10px] rounded-[5px] border bg-gray-100 text-sm outline-none w-full border-gray-300 placeholder:text-gray-500">
                          <option value="" disabled selected hidden>Année Scolaire</option>
                          <option v-for="annee in filteredAnnees" :key="annee" :value="annee">
                            {{ annee }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full relative my-[15px]">
                        <button type="submit" :disabled="isLoading" class="w-full p-[10px] bg-blue-500 hover:bg-blue-600 flex justify-center items-center h-[40px] duration-200 text-center font-bold rounded-[5px] text-sm text-white">
                            <span v-if="!isLoading">S'inscrire</span>
                            <div v-if="isLoading" class="spinner"></div>
                        </button>
                    </div>
                    <div class="my-[10px] relative w-full flex justify-end">
                        <p class="text-[10px] w-full my-[15px] font-semibold text-gray-500">
                            En utilisant notre plateforme, vous acceptez nos <button class="text-blue-500 hover:underline"> Conditions d'utilisation </button> et consentez à notre <button class="text-blue-500 hover:underline"> Politique de confidentialité </button>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useAuthStore } from '@/store/auth';
import { onMounted, ref, computed, watch } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

function goToLoginPage() {
    router.push('/login')
}

const prenoms = ref('')
const nom = ref('')
const email = ref('')
const password = ref('')
const filiere = ref('')
const year = ref('')
const filieres = ref([])
const isLoading = ref(false);
const error = ref(null);
const router = useRouter();
const errorMessage = ref('')
const successMessage = ref('')
const Cpassword = ref('')

const authStore = useAuthStore();
const user = computed(() => authStore.user);

async function handleSubmit() {
  if ( password.value !== Cpassword.value ) {
    errorMessage.value = 'Les mots de passe ne correspondent'
    hideErrorMessageAfterDelay()
    return
  }
  isLoading.value = true;
  try {
    const response = await axios.post('/api/client', {
      firstname: prenoms.value,
      lastname: nom.value,
      email: email.value,
      password: password.value,
      filiere: selectedFiliere.value,
      year: selectedAnnee.value,
    });
    router.push({ path: '/verify', query: { email: email.value } }); // Rediriger vers la page de vérification
  } catch (err) {
    errorMessage.value = 'Une erreur est survenue. Réessayez !';
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false;
  }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

const selectedFiliere = ref('');
const selectedAnnee = ref('');
const filteredAnnees = ref([]);

const fetchFilieres = async () => {
  try {
    const response = await axios.get(`/api/filieres`);
    filieres.value = response.data;
  } catch (err) {
    console.error('Erreur lors du chargement des filières:', err);
  }
};

const filterAnnees = () => {
  if (selectedFiliere.value) {
    const filiere = filieres.value.find(filiere => filiere.nom === selectedFiliere.value);
    if (filiere) {
      filteredAnnees.value = filiere.annee; // Assure-toi que `annee` est bien un tableau dans le modèle
    } else {
      filteredAnnees.value = [];
    }
  } else {
    filteredAnnees.value = [];
  }
};

onMounted(() => {
    if (user.value) {
        router.push('/dashboard')
    }
})
onMounted(() => {
    fetchFilieres();
})

watch(selectedFiliere, () => {
  filterAnnees();
});

</script>

<style>

</style>