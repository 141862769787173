// stores/menu.ts
import { defineStore } from 'pinia';

// Définition de l'interface de l'état
interface MenuState {
  isFirstMenuOpen: boolean;
  isSecondMenuOpen: boolean;
}

export const useMenuStore = defineStore('menuStore', {
  // Déclaration du type d'état
  state: (): MenuState => ({
    isFirstMenuOpen: false,
    isSecondMenuOpen: false,
  }),
  
  actions: {
    // Action pour basculer le premier menu
    toggleFirstMenu() {
      this.isFirstMenuOpen = !this.isFirstMenuOpen;
      if (this.isFirstMenuOpen) {
        this.isSecondMenuOpen = false;
      }
    },

    // Action pour basculer le deuxième menu
    toggleSecondMenu() {
      this.isSecondMenuOpen = !this.isSecondMenuOpen;
      if (this.isSecondMenuOpen) {
        this.isFirstMenuOpen = false;
      }
    },

    // Action pour fermer les deux menus
    closeMenus() {
      this.isFirstMenuOpen = false;
      this.isSecondMenuOpen = false;
    },
  },
});
