<template>
    <header class="w-full h-[70px] sticky top-0 z-50 shadow-4xl bg-white flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] h-full flex justify-center items-center w-full relative">
            <div class="flex justify-between items-center w-full h-full relative">
                <div class="text-2xl uppercase font-bold h-[70px] relative">
                    <img src="../assets/logo.jpg" alt="" class="h-full w-[70px]">
                </div>
                <nav class="md:flex hidden h-full relative items-center justify-center">
                    <ul class="h-full flex items-center justify-center">
                        <li
                        v-for="(link, index) in navLinks"
                        :key="index"
                        class="mx-[10px] text-sm cursor-pointer text-gray-800 font-bold h-full flex items-center capitalize"
                        :class="{'border-b-[5px] border-blue-950': isActiveLink(link.hash)}"
                        @click="scrollToSection(link.hash)"
                        >
                            {{ link.name }}
                        </li>
                    </ul>
                </nav>
                <div v-if="token" class="flex items-center">
                    <button @click="goToDashboardPage()" class="p-[10px] rounded-[5px] text-sm font-bold text-white bg-blue-950 border border-blue-950">Dashboard</button>
                    <button @click="logout()" class="ml-[20px] p-[10px] rounded-[5px] text-sm font-bold text-white bg-blue-950 border border-blue-950">
                        <span class="md:flex hidden">Se déconnecter</span> 
                        <span class="md:hidden flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M16 13v-2H7V8l-5 4l5 4v-3z"/><path fill="currentColor" d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2"/></svg>
                        </span>
                    </button>
                </div>
                <div v-else>
                    <button @click="goToLoginPage()" class="ml-[15px] p-[10px] rounded-[5px] text-sm font-bold bg-transparent text-blue-950 border border-blue-950">Se connecter</button>
                    <button @click="goToRegisterPage()" class="ml-[15px] p-[10px] rounded-[5px] text-sm font-bold text-white bg-blue-950 border border-blue-950">S'inscrire</button>
                </div>
            </div>
        </div>
    </header>
    <section id="apropos" class="h-auto py-[50px] flex justify-center items-center w-full relative bg-blue-50">
        <div class="container max-w-[1024px] px-[20px] flex md:flex-row flex-col justify-center items-center relative w-full">
            <div class="md:w-6/12 w-full">
                <h1 class="md:text-5xl text-3xl font-bold text-gray-800">
                    Accédez à des Epreuves, Exercices, Documents de Qualité pour Réussir Vos Examens !
                </h1>
                <div class="my-[15px] md:my-[25px] flex items-center">
                    <i class="fa-solid fa-users mr-[5px]"></i>
                    <p class="text-sm font-bold text-gray-800"><span class="text-2xl">50+</span> Utilisateurs actifs</p>
                </div>
                <div class="flex items-center">
                    <button @click="goToLoginPage()" class="text-white font-bold text-sm bg-blue-950 p-[10px] rounded-[5px]">
                        Commencer maintenant
                    </button>
                    <div class="ml-[10px] flex items-center">
                        <i class="fa-solid fa-circle-play mr-[5px]"></i>
                        <p class="text-sm font-semibold flex items-center text-gray-500"> Regarder une vidéo explicative </p>
                    </div>
                </div>
            </div>
            <div class="md:w-6/12 w-full mt-[40px] md:mt-0">
                <img src="../assets/images/revixo.png" alt="">
            </div>
        </div>
    </section>
    <section class="py-[50px] flex justify-center items-center relative">
        <div class="w-full relative container px-[20px] flex flex-col justify-center items-center max-w-[1024px]">
            <div class="w-full relative flex justify-center items-start md:flex-row flex-col">
                <div class="md:w-6/12 w-full font-bold">
                    <p class="text-gray-400">Revixo: Votre Compagnon d'Etude en Ligne</p>
                    <h1 class="text-gray-700 text-4xl text-left my-[10px] capitalize">A propos de nous</h1>
                </div>
                <div class="md:w-6/12 w-full md:font-bold font-semibold">
                    <p class="text-gray-500">
                        Bienvenue sur Revixo, la plateforme dédiée aux étudiants souhaitant accéder facilement aux ressources nécessaires pour optimiser leur révision. Grâce à nos abonnements sur mesure, accédez aux ressources adaptées à votre filière et à votre année d'étude, pour briller à vos examens.
                    </p>
                </div>

            </div>
            <div class="w-full relative md:h-[500px] h-auto flex flex-col md:flex-row justify-between items-center mt-[20px]">
                <div class="md:w-[calc(50%-10px)] w-full h-full rounded-[20px] hidden md:flex items-center justify-between flex-col">
                    <div class="w-full md:h-[calc(50%-10px)] h-[150px] overflow-hidden flex justify-center items-center relative my-[10px] md:my-0 rounded-[20px]">
                        <img src="../assets/images/revixo2.png" alt="" class="w-full h-full object-contain object-center">
                    </div>
                    <div class="w-full md:h-[calc(50%-10px)] h-[150px] overflow-hidden flex justify-center items-center relative my-[10px] md:my-0 rounded-[20px]">
                        <img src="../assets/images/revixo3.png" alt="" class="w-full h-full object-contain object-center">
                    </div>
                </div>
                <div class="md:w-[calc(50%-10px)] w-full md:h-full h-[300px] overflow-hidden flex justify-center items-center relative my-[10px] md:my-0 rounded-[20px]">
                    <img src="../assets/images/revixo4.png" alt="" class="w-full h-full object-contain object-center">
                </div>
            </div>
        </div>
    </section>
    <section id="services" class="py-[50px] md:pt-[100px] w-full relative flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] flex justify-center items-center flex-col w-full relative">
            <h1 class="md:text-4xl text-xl font-bold text-gray-800 mb-[50px]  text-left w-full">Nos services </h1>
            <div class="w-full relative flex justify-center items-center flex-wrap">
                <div class="md:w-[calc(100%/4)] w-[50%] h-[180px] flex flex-col justify-center items-center text-white md:rounded-l-[20px] rounded-tl-[20px] p-[20px] bg-blue-950/90">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48"><g fill="none"><path d="M0 0h48v48H0z"/><path fill="currentColor" fill-rule="evenodd" d="M39 13a3 3 0 0 0-3 3v2h6v-2a3 3 0 0 0-3-3m3 7h-6v16.5l3 4.5l3-4.5zM6 9v30a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3m14 6a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-1 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2zm-9-3v3h3v-3zm-1-2h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1m6.707-10.293a1 1 0 0 0-1.414-1.414L13 17.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L13 20.414z" clip-rule="evenodd"/></g></svg>
                    <h1 class="text-sm font-bold text-center mb-[10px]">Epreuves et Corrigés</h1>
                    <p class="text-[11px] hidden md:flex font-semibold text-center">Revixo vous propose un accès illimité à une vaste collection d'épreuves et corrigés pour vous aider à vous préparer efficacement pour vos examens</p>
                </div>
                <div class="md:w-[calc(100%/4)] w-[50%] md:h-[220px] h-[180px] flex flex-col justify-center items-center text-white md:rounded-[20px] rounded-tr-[20px] p-[20px] bg-blue-950 shadow-4xl">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20"><path fill="currentColor" d="M10 6.5V2H5.5A1.5 1.5 0 0 0 4 3.5v5.55Q4.243 9 4.5 9h2c.818 0 1.544.393 2 1h5a.5.5 0 0 1 0 1H8.95l.008.042A2.5 2.5 0 0 1 10.5 12h3a.5.5 0 0 1 0 1h-2.55q.05.243.05.5v.5h2.5a.5.5 0 0 1 0 1H11v2.5q0 .257-.05.5h3.55a1.5 1.5 0 0 0 1.5-1.5V8h-4.5A1.5 1.5 0 0 1 10 6.5m1 0V2.25L15.75 7H11.5a.5.5 0 0 1-.5-.5M3 12v-.5A1.5 1.5 0 0 1 4.5 10h2A1.5 1.5 0 0 1 8 11.5v.5h.5a1.5 1.5 0 0 1 1.5 1.5V15H8v-.5a.5.5 0 0 0-1 0v.5H4v-.5a.5.5 0 0 0-1 0v.5H1v-1.5A1.5 1.5 0 0 1 2.5 12zm4-.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v.5h3zM8 16h2v1.5A1.5 1.5 0 0 1 8.5 19h-6A1.5 1.5 0 0 1 1 17.5V16h2v.5a.5.5 0 0 0 1 0V16h3v.5a.5.5 0 0 0 1 0z"/></svg>
                    <h1 class="text-sm font-bold text-center mb-[10px]">Exercices pratiques corrigés</h1>
                    <p class="text-[11px] hidden md:flex font-semibold text-center">Entrainez-vous avec des exercices adaptés à votre niveau et progressez rapidement grâce à des corrigés détaillés.</p>
                </div>
                <div class="md:w-[calc(100%/4)] w-[50%] h-[180px] flex flex-col justify-center items-center text-white md:rounded-none rounded-bl-[20px] p-[20px] bg-blue-950/90">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="m231.65 194.55l-33.19-157.8a16 16 0 0 0-19-12.39l-46.81 10.06a16.08 16.08 0 0 0-12.3 19l33.19 157.8A16 16 0 0 0 169.16 224a16.3 16.3 0 0 0 3.38-.36l46.81-10.06a16.09 16.09 0 0 0 12.3-19.03M136 50.15v-.09l46.8-10l3.33 15.87L139.33 66Zm6.62 31.47l46.82-10.05l3.34 15.9L146 97.53Zm6.64 31.57l46.82-10.06l13.3 63.24l-46.82 10.06ZM216 197.94l-46.8 10l-3.33-15.87l46.8-10.07l3.33 15.85zM104 32H56a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16M56 48h48v16H56Zm0 32h48v96H56Zm48 128H56v-16h48z"/></svg>
                    <h1 class="text-sm font-bold text-center mb-[10px]">Bibliothèque</h1>
                    <p class="text-[11px] hidden md:flex font-semibold text-center">Accédez à des documents spécifiques à votre filière et année d'étude pour une révison ciblée et efficace.</p>
                </div>
                <div class="md:w-[calc(100%/4)] w-[50%] h-[180px] flex flex-col justify-center items-center text-white md:rounded-r-[20px] rounded-br-[20px] p-[20px] bg-blue-950/90">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M4 7v12h15v2H4c-2 0-2-2-2-2V7zm17.3-4H7.7C6.76 3 6 3.7 6 4.55v10.9c0 .86.76 1.55 1.7 1.55h13.6c.94 0 1.7-.69 1.7-1.55V4.55C23 3.7 22.24 3 21.3 3M8 5h5v6H8zm13 10H8v-2h13zm0-4h-6V9h6zm0-4h-6V5h6z"/></svg>
                    <h1 class="text-sm font-bold text-center mb-[10px]">Recommandations périodiques</h1>
                    <p class="text-[11px] hidden md:flex font-semibold text-center">Recevez des recommandations régulières en matière d'Epreuve, d'Exercice ou de Document</p>
                </div>
            </div>
        </div>
    </section>
    <section id="tarif" class="w-full py-[50px] md:pt-[100px] relative flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] flex justify-center items-center flex-col w-full relative">
            <h1 class="text-center text-2xl mb-[20px] font-bold text-gray-800">
                Nos différents abonnements
            </h1>
            <div class="flex w-full relative md:flex-row flex-col-reverse justify-center items-center">
                <div class="md:w-[300px] w-[340px] h-auto relative min-w-[300px] my-[10px] mx-0 md:mx-[20px] min-h-[500px] border border-black shadow-4xl rounded-[25px] max-w-[100%]">
                    <div class="w-full h-[150px] rounded-[20px] flex flex-col justify-center items-center relative overflow-hidden">
                        <div class="w-[120px] h-[120px] relative flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/standar.jpeg" alt="" >
                        </div>
                        <h1 class="text-sm font-bold text-gray-800 mt-[10px]">Standard</h1>
                    </div>
                    <div class="w-full p-[20px]">
                        <ul>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Epreuves et Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Recommandations périodiques</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Actualités et informations</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-xmark text-red-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Bibliothèque</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-xmark text-red-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Exercices Pratiques Corrigés</p>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-[20px] p-[20px] border-t flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            3000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                        </h1>
                        <p class="text-xs mb-[10px] font-bold text-gray-500 text-center">
                        </p>
                        <button @click="goToRegisterPage()" class="bg-blue-950 mt-[10px] font-bold text-white p-[10px] rounded-[5px]">
                            Commencer
                        </button>
                    </div>
                </div>
                <div class="md:w-[300px] w-[340px] h-auto relative min-w-[300px] my-[10px] mx-0 md:mx-[20px] min-h-[500px] border border-blue-600 shadow-4xl rounded-[25px] max-w-[100%]">
                    <div class="w-full h-[150px] rounded-[20px] flex flex-col justify-center items-center relative overflow-hidden">
                        <div class="w-[120px] h-[120px] relative flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/premium.jpeg" alt="" >
                        </div>
                        <h1 class="text-sm font-bold text-gray-800 mt-[10px]">Premium</h1>
                    </div>
                    <div class="w-full p-[20px]">
                        <ul>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Epreuves et Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Exercices Pratiques Corrigés</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Recommandations périodiques</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Bibliothèque</p>
                            </li>
                            <li class="flex items-center my-[5px]">
                                <i class="fa-solid fa-check text-green-600 text-xl w-[30px] text-center"></i>
                                <p class="text-sm font-semibold text-gray-600">Actualités et informations</p>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-[20px] p-[20px] border-t flex flex-col justify-center items-center border-gray-200">
                        <h1 class="text-3xl font-bold text-gray-800 text-center">
                            5000 <span class="text-[10px] text-gray-500 font-bold">FCFA / an</span>
                        </h1>
                        <p class="text-xs mb-[10px] font-bold text-gray-500 text-center">
                        </p>
                        <button @click="goToRegisterPage()" class="bg-blue-950 mt-[10px] font-bold text-white p-[10px] rounded-[5px]">
                            Commencer
                        </button>
                    </div>
                    <div class="absolute top-[-20px] text-white p-[10px] bg-blue-700 font-bold text-sm rounded-[5px] left-[50%] translate-x-[-50%]">
                        Recommandé
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="contact" class="py-[50px] flex justify-center items-center relative">
        <div class="w-full relative container px-[20px] flex justify-center items-center max-w-[1024px]">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="w-full font-bold flex justify-center items-center flex-col">
                    <p class="text-gray-600 text-center max-w-[600px] px-[20px]">Rejoignez Revixo dès aujourd'hui et commencez à explorer nos ressources éducatives pour atteindre vos objectifs académiques</p>
                    <button @click="goToRegisterPage()" class="mt-[20px] rounded-[5px] p-[10px] text-sm font-bold text-white bg-blue-950">
                        S'inscrire maintenant
                    </button>
                </div>

            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import FooterComponent from "@/components/FooterComponent.vue";
import { ref } from "vue"
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useAuthStore } from '@/store/auth';
import axios from 'axios'

const router = useRouter()
const authStore = useAuthStore();
const token = computed(() => authStore.token);
const isLoading = ref(false)

const logout = async () => {
    isLoading.value = true
  try {
    await axios.post('/api/client/logout', {}, {
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    });
    authStore.logout();
    router.push('/');
  } catch (err) {
    if (err.response && err.response.status === 401) {
      errorMessage.value = "Session expirée. Vous êtes déconnecté automatiquement.";
      hideErrorMessageAfterDelay()
    } else {
      errorMessage.value = "Erreur lors de la déconnexion.";
      hideErrorMessageAfterDelay()
    }
  } finally {
    isLoading.value = false;
  }
};

function goToLoginPage() {
    router.push('/login')
}
function goToRegisterPage() {
    router.push('/register')
}
function goToDashboardPage() {
    router.push('/dashboard')
}

const navLinks = [
  { name: "à propos", hash: "#apropos" },
  { name: "services", hash: "#services" },
  { name: "Tarif", hash: "#tarif" },
  { name: "Contact", hash: "#contact" },
];

// Fonction pour défiler vers la section correspondante
const scrollToSection = (hash) => {
  const section = document.querySelector(hash);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

// Fonction pour activer le lien correspondant à la section visible
const isActiveLink = (hash) => {
  const section = document.querySelector(hash);
  if (!section) return false;

  const top = section.getBoundingClientRect().top;
  return top >= 0 && top <= window.innerHeight / 2;
};

// Détecter le scroll pour activer dynamiquement les liens
window.addEventListener("scroll", () => {
  navLinks.forEach((link) => {
    isActiveLink(link.hash);
  });
});
</script>
