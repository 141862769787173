<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <div class="fixed md:top-[20px] top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
            <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-red-700 my-[10px] font-bold text-center p-[20px]">
                {{ errorMessage }}
            </div>
            <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl bg-white text-green-700 my-[10px] font-bold text-center p-[20px]">
                {{ successMessage }}
            </div>
        </div>
        <section id="windows" class="bg-blue-50 parent w-full h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <div class="w-full relative flex justify-center items-center flex-col">
                <div class="mb-[25px] w-full relative flex flex-col">
                    <div class="w-full relative flex my-[10px] justify-between items-center">
                        <h1 class="text-sm font-bold ">Liste des exercices</h1>
                        <div class="flex items-center">
                            <div class="w-[300px] md:max-w-[300px] max-w-[180px] mr-[15px] relative">
                                <label for="" class="absolute top-[10px] right-[10px] text-sm font-bold text-gray-700"><i class="fa-solid fa-search"></i></label>
                                <input v-model="searchQuery" type="text" placeholder="Recherchez une épreuve" class="w-full p-[10px] pr-[30px] bg-transparent border border-gray-300 rounded-[5px] text-sm outline-none">
                            </div>
                            <div @click="toggleFiltre()" class="w-[35px] h-[35px] cursor-pointer rounded-[50%] flex justify-center items-center bg-gray-200 text-gray-600">
                                <i class="fa-solid fa-filter text-sm font-bold"></i>
                            </div>
                        </div>
                    </div>
                    <div v-if="filtre" class="w-full relative flex items-center my-[10px]">
                        <div class="w-full relative">
                            <!-- Select dynamique pour les matières -->
                            <select v-model="selectedMatiere" class="w-full p-[10px] rounded-[5px] text-sm outline-none border border-gray-300 bg-transparent text-gray-600">
                                <option value="">Toutes les matières</option>
                                <option v-for="matiere in matieresUniques" :key="matiere" :value="matiere">{{ matiere }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <!-- Liste des épreuves paginées -->
                    <router-link v-for="(exercice, index) in paginatedexercices" :key="index" :class="['w-full md:px-[20px] px-[10px] cursor-pointer my-[5px] py-[10px] rounded-[5px] bg-white flex justify-between items-center', { 'cursor-not-allowed opacity-50': !exercice.isActive }]" :style="!exercice.isActive ? 'filter: blur(3px); pointer-events: none;' : ''" :to="exercice.isActive ? '/exercices/'+ exercice._id : '#'">
                        <div class="flex items-center md:w-auto w-[calc(100%-80px)]">
                            <div class="w-[35px] h-[40px] min-w-[35px] min-h-[40px] relative overflow-hidden flex justify-center items-center">
                                <img src="../assets/images/epreuve.jpeg" alt="" class="w-full h-full absolute object-contain">
                            </div>
                            <div class="flex flex-col ml-[10px]">
                                <h1 class="text-sm clamp font-bold text-gray-800">{{ exercice.matiere }}</h1>
                                <p class="text-[10px] clamp font-semibold text-gray-500">
                                    {{ exercice.titre }}
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center">
                            <div class="p-[10px] rounded-[5px] bg-gray-100 flex text-[10px] font-bold text-gray-600">
                                <div class="hidden md:flex items-center">
                                    <i class="fa-solid fa-calendar pr-[5px]"></i>
                                    <span>{{ exercice.createdAt }}</span>
                                </div>
                                <span class="mx-[5px] hidden md:flex">|</span>
                                <div class="flex items-center">
                                    <i class="fa-solid fa-star pr-[5px]"></i>
                                    <span>5</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-arrow-right ml-[10px] text-sm"></i>
                        </div>
                    </router-link>
                    <div class="md:py-[50px] py-[25px] flex w-full md:justify-end justify-center items-center px-[20px]">
                        <div v-for="page in totalPages" :key="page" @click="goToPage(page)" 
                        :class="{'bg-blue-950 text-white border border-blue-950': currentPage === page, 'bg-white': currentPage !== page}" 
                        class="w-[30px] h-[30px] mx-[5px] rounded-[5px] shadow-4xl cursor-pointer border flex justify-center items-center font-bold text-sm text-center">
                            {{ page }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isLoading && filteredExercices.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucune épreuve trouvée.
            </div>
            <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                <div class="spinner"></div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, onMounted, computed } from 'vue'
import { useRouter} from 'vue-router';
import { useAuthStore } from '@/store/auth';
import axios from 'axios'

const authStore = useAuthStore();
const user = computed(() => authStore.user);
const router = useRouter()
const isLoading = ref(false)
const filtre = ref(true)
const exercices = ref([])
const errorMessage = ref('')
const successMessage = ref('')

const searchQuery = ref('');
const selectedMatiere = ref('');
const selectedYear = ref('');

// Tableau pour stocker les matières et les années disponibles
const matieres = ref([]);
const years = ref([]);

// Fonction pour basculer l'affichage des filtres
function toggleFiltre() {
    filtre.value = !filtre.value;
}

// Récupérer les exercices et filtrer par filière, année, etc.
const fetchexercices = async() => {
    isLoading.value = true;
    try {
        const response = await axios.get('/api/exercices');
        const allExercices = response.data;

        const filteredExercices = allExercices.filter(epr => {
            return epr.filiere === user.value.filiere && epr.year === user.value.year;
        });

        exercices.value = filteredExercices

    } catch {
        errorMessage.value = "Erreur lors du chargement des exercices";
        hideErrorMessageAfterDelay();
    } finally {
        isLoading.value = false;
    }
};

const matieresUniques = computed(() => {
    const allMatieres = exercices.value.map(exercice => exercice.matiere);
    return [...new Set(allMatieres)]; // Pour obtenir des matières uniques
});

const filteredExercices = computed(() => {
    return exercices.value.filter(exercice => {
        const matchesMatiere = selectedMatiere.value ? exercice.matiere === selectedMatiere.value : true;
        const matchesYear = selectedYear.value ? exercice.year === selectedYear.value : true;
        const matchesQuery = searchQuery.value ? exercice.titre.toLowerCase().includes(searchQuery.value.toLowerCase()) : true;
        return matchesMatiere && matchesYear && matchesQuery;
    });
});

// Gestion de la pagination
const currentPage = ref(1);
const itemsPerPage = 10;

const totalPages = computed(() => Math.ceil(filteredExercices.value.length / itemsPerPage));

const paginatedexercices = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredExercices.value.slice(start, end);
});

function goToPage(page) {
    currentPage.value = page;
    scrollToTop()
}

// Masquer les messages après un délai
function hideErrorMessageAfterDelay() {
    setTimeout(() => errorMessage.value = "", 3000);
}
function hideSuccessMessageAfterDelay() {
    setTimeout(() => successMessage.value = "", 3000);
}

const scrollToTop = () => {
    const windows = document.getElementById("windows")
    windows.scrollTo({
        top: -0,
        behavior: 'smooth'
    })
}

onMounted(() => {
    fetchexercices();
});
const isStandar = ref(null)
const isPremium = ref(null)
onMounted(() => {
    if(user.value.subscription == "66dbc2023c588a48f7c65fec" || user.value.subscription == "66dbc23a3c588a48f7c65fee") {
        isStandar.value = true
        isPremium.value = false
        //router.push('/dashboard')
    } else if (user.value.subscription == "66dc16f63c588a48f7c65ff6" || user.value.subscription == "66dc171e3c588a48f7c65ff8") {
        isStandar.value = false
        isPremium.value = true
    } else {
        isStandar.value = false
        isPremium.value = false
    }
})
</script>

<style scoped>
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #4F46E5;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
