<template>
    <main class="bg-[#191442] flex justify-center items-center w-full relative h-screen">
        <HeaderComponent />
        <section class="bg-blue-50 parent flex flex-col w-full relative h-screen md:rounded-l-[25px] md:p-[50px] p-[20px] mt-[120px] md:mt-0 overflow-y-auto">
            <h1 class="md:text-2xl text-sm font-bold text-gray-800 mb-[20px]">Les Actualités disponibles</h1>
            <div class="w-full relative flex md:justify-center lg:justify-between justify-between items-center flex-wrap rounded-[25px]">
                <div v-for="(info, index) in Infos" :key="index" class="md:w-[200px] lg:w-[calc(100%/3-10px)] w-[calc(50%-10px)] h-auto min-h-[150px] md:m-[10px] lg:mx-0 my-[10px] overflow-hidden cursor-pointer relative rounded-[20px] shadow-4xl bg-white">
                    <div @click="toggleContent(info._id)" class="p-[10px]">
                        <h1 class="text-sm font-bold text-gray-800 line-clamp-2 hover:underline">{{ info.titre }}</h1>
                        <p class="text-xs font-semibold text-gray-500 mt-[5px] line-clamp-3">
                            {{ info.content }}
                        </p>
                        <div class="my-[10px] text-gray-500 text-xs font-bold flex items-center">
                            <i class="fa-solid fa-clock-rotate-left mr-[5px]"></i>
                            <span>{{ formatDate(info.createdAt) }}</span> 
                        </div>
                    </div>
                    <div v-if="showContent === info._id" class="fixed w-[calc(100%-40px)] md:w-[calc(80%-370px)] left-[20px] md:left-[300px] overflow-y-auto parent rounded-[20px] p-[20px] md:h-[90%] h-[calc(100%-110px)] md:top-[50%] top-[90px] md:translate-y-[-50%] bg-gray-100 shadow-4xl z-[100]">
                        <button class="w-[30px] h-[30px] rounded-[50%] bg-gray-200 flex justify-center items-center absolute top-[20px] right-[20px]">
                            <i @click="toggleContent(info._id)" class="fa-solid fa-xmark font-bold text-gray-800 text-xl"></i>
                        </button>
                        <div class="mt-[50px]">
                            <h1 class="text-xl font-bold text-gray-800 line-clamp-2 hover:underline">{{ info.titre }}</h1>
                            <p class="text-sm mb-[10px] text-gray-500 mt-[5px]" v-html="info.content" style="white-space: pre-wrap;"></p>
                        </div>
                        <div class="my-[10px] absolute top-[20px] left-[20px] text-gray-500 text-xs font-bold flex items-center">
                            <i class="fa-solid fa-clock-rotate-left mr-[5px]"></i>
                            <span>{{ formatDate(info.createdAt) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="!isLoading && Infos.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                Aucune info trouvées.
            </div>
            <div v-if="isLoading" class="w-full h-full fixed left-0 top-0 z-10 bg-gray-800/50 flex justify-center items-center">
                <div class="spinner"></div>
            </div>
        </section>
        <LateralBar />
    </main>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import LateralBar from '../components/LateralBar.vue'
import { ref, onMounted } from 'vue'
import axios from "axios"

const showContent = ref(null)
const toggleContent = (id) => {
    showContent.value = showContent.value === id ? null : id;
}

const Infos = ref([])
const errorMessage = ref('')
const successMessage = ref('')
const isLoading = ref(false)

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR'); 
}

const fetchInfo = async () => {
    isLoading.value = true
    try {
        const response = await axios.get('/api/news')
        Infos.value = response.data
    } catch (error) {
        errorMessage.value = 'Erreur lors du chargement des informations'
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
}

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() => {
    fetchInfo()
})
</script>

<style>

</style>
